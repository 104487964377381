const UTILS = {};

UTILS.addLeadingZero = function(val, numberZero) {
  while (val.toString().length < numberZero) {
    val = "0" + val;
  }
  return val;
};

UTILS.numberWithSpaces = function(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

UTILS.trackEvent = function (name, params = {}) {
  if (typeof gtag === "undefined") {
    return null;
  } 
  
  gtag('event', name, params);
};

export default UTILS;