import { TweenLite } from "gsap/TweenMax";
import Touche from "touchejs";

export default class Slider{
  constructor (settings = {}) {
    this.element = settings.element;

    this.onSlide = settings.onSlide;
  
    this.sliding = false;

    this.index = 0;
    

    this.slides = this.element.querySelectorAll('.selector__character');
  
    this.bindEvents();
  }
  bindEvents () {
    
    this.element.querySelector('.arrow--left').addEventListener('click', () => {
      this.slide(-1);
    });

    this.element.querySelector('.arrow--right').addEventListener('click', () => {
      this.slide(1);
    });

    Touche(this.element.querySelector('.selector__content'))
      .swipe({
        end: (e, data) => {
          if (data.swipe.direction === "left") {
            this.slide(-1);
          } else if (data.swipe.direction === "right") {
            this.slide(1);
          }
        }
      });
  }
  slide (direction = 1) {
    const xMove = 20;
    const delay = 0.2;


    if (this.sliding) return false;
    
    this.sliding = true;

    TweenLite.to(this.slides[this.index], .4, {opacity : 0, x: xMove * -direction});

    if (direction === 1) {
      this.index = this.index < this.slides.length - 1 ? this.index + 1 : 0;
    } else {
      this.index = this.index > 0 ? this.index - 1 : this.slides.length - 1;
    }

    TweenLite.fromTo(this.slides[this.index], .4, 
      {opacity:0, x: xMove * direction}, 
      {opacity : 1, x:0, delay, onComplete: ()=>{
        this.sliding = false;        
      }
    });

    this.onSlide(this.index);
  }
}