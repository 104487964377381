import SETTINGS from "../Settings";
import Screen from "./Screen";
import StartScreen from "./StartScreen";
import LoadingScreen from "./LoadingScreen";
import HighScoreScreen from "./HighScoreScreen";
import ScoreScreen from "./RecordScreen";
import SelectionScreen from "./SelectionScreen";
import GameOverScreen from "./GameOverScreen";
import TutorialScreen from "./TutorialScreen";
import _ from 'lodash';
import Controller from '../lib/Controller';

class ScreensManager {
  constructor (app) {
    this.app = app;

    this.screens = {
      loading: new LoadingScreen('screen-loading', this),
      welcome: new Screen('screen-welcome', this),
      selection: new SelectionScreen('screen-selection', this),
      start: new StartScreen('screen-start', this),
      gameover: new GameOverScreen('screen-gameover', this),
      score: new ScoreScreen('screen-score', this),
      highscores: new HighScoreScreen('screen-highscores', this),
      interface: new Screen('interface', this),
      challenge: new Screen('screen-challenge', this),
      rotation: new Screen('screen-rotation', this),
      tutorial: new TutorialScreen('screen-tutorial', this),
      pause: new Screen('screen-pause', this),    
    };

    this.bindLinks();

    this.bindKeyboard();

    this.loaderOpened = true;

    this.bindSpecialActions();
  }
  open (screen, closeOther = true, callback = null) {
    
    if (this.screens[screen]) {
      if (closeOther) {
        this.closeAll();
      }

      this.screens[screen].open(callback);
    
      if(!SETTINGS.FB_ACTIVE && !SETTINGS.ARCADE_MODE) {
        this.app.analytics.onOpenScreen(this.screens[screen]);
      }
    }
  }
  close (screen, callback = null) {

    if (this.screens[screen]) {
      this.screens[screen].close(callback);
    }

  }
  closeAll () {
    
    for (let key in this.screens) {
      if (key === "pause") return false;    
      this.screens[key].close();
    }
    
  }
  bindLinks () {
    const self = this;
    
    [].forEach.call(document.querySelectorAll('[data-open-screen]'), function(el) {
      el.addEventListener('click', () => {
        self.open(el.getAttribute('data-open-screen'));
      });
    });

  }
  
  get hasScreenOpen () {
    
    return this.openedScreen !== null;
    
  }

  get openedScreen () {
    for (let key in this.screens) {
      if (this.screens[key].opened) {
        return this.screens[key];
      }
    }

    return null;
  }
  
  closeLoader () {
    if (!this.loaderOpened) return null;

    this.loaderOpened = false;
        
    this.close('loading', () =>{
      document.body.removeChild(this.screens.loading.element);
      if (SETTINGS.SCREEN)  {
        this.open(SETTINGS.SCREEN, false);
      }else if (!SETTINGS.SKIP_INTRO)  {
        this.open('welcome');
      }
    });

    
  }
  
  bindSpecialActions () {
    document.getElementById('resume-button').addEventListener('click' , () => {
      this.app.runner.resume();
    });
  }

  bindKeyboard () {
    const KEYBOARD_CODE = {
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      enter: 13
    };

    window.addEventListener('keydown', (e) =>{

      const currentScreen = this.openedScreen;

      if ( !currentScreen ) return null;

      _.mapKeys(KEYBOARD_CODE, (code, key) => {

        if (e.keyCode === code) {
          switch (key) {
            case 'enter':
              currentScreen.onEnter(e);
              break;
            case 'left':
            case 'right':
            case 'up':
            case 'down':
              currentScreen.onDirectionnal(key, e);
              break;
          }
        }
      });
    });

    //ARCADE
    Controller.search();

    window.addEventListener('gc.button.press', (event) => {
      const currentScreen = this.openedScreen;

      if ( !currentScreen ) return null;

      const button = event.detail.name;

      if (button === SETTINGS.ARCADE_BUTTON.up || button === SETTINGS.ARCADE_BUTTON.down ) {
        currentScreen.onEnter();
      } else if (button === SETTINGS.ARCADE_BUTTON.joystick.up) {
        currentScreen.onDirectionnal("up");
      } else if (button === SETTINGS.ARCADE_BUTTON.joystick.right) {
        currentScreen.onDirectionnal("right");
      } else if (button === SETTINGS.ARCADE_BUTTON.joystick.down) {
        currentScreen.onDirectionnal("down");
      } else if (button === SETTINGS.ARCADE_BUTTON.joystick.left) {
        currentScreen.onDirectionnal("left");        
      }

    }, false);

    window.addEventListener('gc.analog.start', (event) => {
      const currentScreen = this.openedScreen;

      if ( !currentScreen ) return null;

      let position = event.detail.position;
      
      if (Math.abs(position.x) < SETTINGS.ARCADE_BUTTON.threshold && Math.abs(position.y) < SETTINGS.ARCADE_BUTTON.threshold ) {
        return;
      }

      if(position.x > 0) {
        currentScreen.onDirectionnal("right");
      } else if (position.x < 0) {
        currentScreen.onDirectionnal("left");
      }


      if(position.y > 0) {
        currentScreen.onDirectionnal("down");
      } else if (position.y < 0) {
        currentScreen.onDirectionnal("up");
      }

    }, false);



    window.addEventListener('gc.controller.found', (event) => {

      this.controller = Controller.getController(0);

      if (this.openedScreen) {
        this.openedScreen.onEnter();
      }
    }, false);

  } 

}

export default ScreensManager;