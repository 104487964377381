import { TweenLite } from "gsap/TweenMax";
import SETTINGS from "../Settings";
import Screen from "./Screen";

export default class StartScreen extends Screen {
  constructor (id, screensManager) {
    super(id, screensManager);

    this.startCountdown = 3;

    this.delay = 1;

    this.startMessage = "GO";

    this.index = this.startCountdown;

    this.countdownTag = this.element.querySelector('.countdown .countdown__index');

    this.countdownTag.innerText = this.startCountdown;

  }
  countdown () {

    this.index -= 1;

    if (this.index === 0) {

      this.countdownTag.innerText = this.startMessage;

      this.screensManager.app.audio.play('go');

      setTimeout(()=>{
        this.close();
      }, this.delay * 1000);
      
    } else {

      this.countdownTag.innerText = this.index;

      this.screensManager.app.audio.play('top');

      setTimeout(()=>{
        this.countdown();
      }, this.delay * 1000);

    }
  }
 
  open (callback = null) {
    if (!super.open(callback)) return null;
    
    this.index = this.startCountdown;

    this.countdownTag.innerText = this.index;

    setTimeout(()=>{
      this.countdown();
    }, this.delay * 1000);
    
    this.screensManager.app.runner.introduction();
  }
  close (callback = null) {
    super.close(() => {
      this.screensManager.app.runner.start();
    });
  } 
};