import base64 from 'base64-encode-decode';
import SETTINGS from './Settings';

export default class Challenge {
  constructor (app) {
    this.app = app;

    this.options = {};
   
    if (!SETTINGS.ARCADE_MODE) {
      this.active = this.validate();    
    }
  }
  arcadeChallenger (response) {
    let result = JSON.parse(response);

    if (!result) return false;

    if (!result.scores) return false;

    if (!result.scores.length > 0) return false;

    this.challenger = {
      name: result.scores[0].name,
      score: parseInt(result.scores[0].score)
    };

    this.active = true;
  }
  validate () {
    window.location.search.slice(1).split('&').map((option) => {
      const keyValue = option.split('=');
      this.options[keyValue[0]] = decodeURIComponent(keyValue[1]);
    });

    if (!this.options.tm || !this.options.dpe || !this.options.avs ) {
      return false;
    }

    const score = this.decode(this.options.dpe, this.options.avs);
    const name = this.checkName(this.options.tm);

    if (!score || !name) {
      return false;
    }

    this.challenger = {
      name, score
    };

    return true;
  }
  decode (score1, score2) {

    let decodeScore2 = base64.base64Decode(score1);
    let decodeScore1 = base64.base64Decode(score2);

    decodeScore2 = decodeScore2.split("");
    decodeScore2 = decodeScore2.reverse();
    decodeScore2 = decodeScore2.join("").replace(SETTINGS.SECURITY_SLUG, '');

    const reg = new RegExp(/^\d+$/);

    if( !reg.test(decodeScore2)) {
      return false;
    }

    if (decodeScore1 === decodeScore2) {
      return parseInt(decodeScore2);
    } else {
      return false;
    }
  }
  checkName (name) {
    name = base64.base64Decode(name);

    if (name.length !==3) {
      return false;
    } else {
      return name;
    }
  }
  
}