import Item from './Item';
import SETTINGS from '../../Settings';

const IMGS = [
  {
    texture: 'tableau_001',
    scale: {x:1.2, y: 1.1},
    y: 0.5
  },
  {
    texture: 'tableau_002',
    scale: {x:1.2, y: 1.1},
    y: 0.5
  }
];

class Digits extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "obstacle";

    this.state = {
      up: true,
      rows: 2,
      distanceDelay: 1
    };

  }
  setup () {
    this.scale = 1.1;

    const img = IMGS[this.itemGenerator.state.digitsLength % IMGS.length];

    this.create(img.texture);

    this.sprite.position.set(0, img.y, SETTINGS.Z_TRACK_END);

    this.scene.add(this.sprite);
    this.polishTexture();

    this.itemGenerator.state.digitsLength += 1;
  }
  testHit (player) {
    if ( this.testX(player.x) && this.testZ(player.z) && !player.state.crouching) {
      player.hit(this);
    }
  }
  testX (xPosition) {
    return xPosition >= (this.x - SETTINGS.TRACK_WIDTH) && xPosition <= (this.x + SETTINGS.TRACK_WIDTH);
  }
  reset () {
    super.reset();

    this.x = Math.random() > .5 ? -SETTINGS.TRACK_WIDTH/2 : SETTINGS.TRACK_WIDTH/2;  
  }
}

export { Digits };
