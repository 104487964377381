import Screen from "./Screen";
import base64 from 'base64-encode-decode';
import SETTINGS from '../Settings';
import STATE from '../State';
import UTILS from '../runner/helpers/Utils';
import DpadInput from "./components/DpadInput";

const options = {
  nameMinLength: 3,
  nameMaxLength: 3
};

class RecordScreen extends Screen{
  constructor (id, screensManager) {
    super(id, screensManager);

    this.skipRecord = false;
    this.updateRecord = false;

    this.recordContest = false;
    this.alreadyRecordContest = false;

    this.validateButton = this.element.querySelector('.btn');

    this.recordLabel = this.validateButton.querySelector('.record-label');
    this.nextLabel= this.validateButton.querySelector('.next-label');
    this.sended = false;

    this.input = this.element.querySelector('input');

    this.bindEvents();

    this.bindArcade();

  }
  bindEvents () {
    this.input.addEventListener('input', (e) =>{

      this.switchLabel();

      if (this.input.value.length >= options.nameMinLength && this.input.value.length <= options.nameMaxLength) {
        this.validateButton.classList.remove("btn--disabled");
      } else {
        this.validateButton.classList.add("btn--disabled");
      }
    });
    
    this.input.addEventListener('keydown', (e) =>{

      if (e.keyCode === 13) {
        this.validate();
      } else {
        this.screensManager.app.audio.play('select');
      }
    });
    
    this.validateButton.addEventListener('click', () =>{
      if (!this.opened) return null;
      this.validate();
    });
  }
  switchLabel () {
    this.updateRecord = false;

    if (STATE.lastRecord) {
      if (STATE.lastRecord.name === this.input.value) {
        if (STATE.score > STATE.lastRecord.score) {
          this.labelRecord();
          this.updateRecord = true;
        } else {
          this.labelNext();
        }
      } else {
        this.labelRecord();
      }
    } else {
      this.labelRecord();
    }
  }
  labelNext() {
    this.skipRecord = true;
    this.recordLabel.classList.add('hidden');
    this.nextLabel.classList.remove('hidden');
  }
  labelRecord() {
    this.skipRecord = false;
    this.recordLabel.classList.remove('hidden');
    this.nextLabel.classList.add('hidden');
  }
  open (callback) {
    super.open(callback);

    this.sended = false;

    this.skipRecord = false;
    this.updateRecord = false;
    this.switchLabel();

    if (this.dpadInput) {
      this.dpadInput.reset();
    } else {
      this.input.focus();
    }

    this.screensManager.app.audio.play('end', {main: 'theme', loop: true});
  }
  close (callback) {
  
    if (!super.close(callback)) {
      return false;
    }

    
    this.input.blur();
  }
  validate () {
    if (this.input.value.length >= options.nameMinLength && this.input.value.length <= options.nameMaxLength) {

      if (!this.skipRecord) {
        this.record(this.input.value);

      } else {
        this.close();
        this.screensManager.open('highscores', false);
      }

      this.screensManager.app.audio.play('letsgo');
    }
  }
  record (name) {
    if (this.sended) {
      return false;
    }

    this.sended = true;
    
    const score = STATE.score;

    let url = SETTINGS.DB_URL + '/playerrecord.php';

    const encodedScore = base64.base64Encode(score.toString());
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    xhr.onload = () => {
      if (xhr.status === 200) {
        this.onLoad(xhr.responseText);
      } else if (xhr.status !== 200) {
        this.close();
        this.screensManager.open('highscores', false);
      }
    };

    const data = {
      dw: encodedScore,
      d: SETTINGS.PLATFORM.INAPP.isMobile ? 'M' : 'D',
      p: STATE.character.id,
      t: STATE.time,
    };

    if (this.updateRecord) {
      data.pid = STATE.lastRecord.pid;
    }

    let uri = 'name=' + name;

    for (let key in data) {
      uri += '&' + key + '=' + data[key];
    }

    xhr.send(encodeURI(uri));

    this.screensManager.app.analytics.sendEvent({ga: "btn-save-my-score"});
  }
  onLoad (response) {
    let result = null;

    try {
      result = JSON.parse(response);
    } catch(e) { 
      this.screensManager.open('highscores', false);    
      return this.close();
    }

  
    if (result) {
      STATE.pid = result.id;
    }

    STATE.lastRecord = {
      pid: result.id,
      name: this.input.value,
      score: STATE.score
    };

    if (result.enc === true && !STATE.lastRecord.contestRecorded) {
      STATE.lastRecord.contestRecorded = true;
      this.recordContest = true;
    }

    this.close();
    this.screensManager.open('highscores', false);
  }

  bindArcade () {
    this.dpadInput = false;

    const dpadTag = this.element.querySelector('.dpad-input');

    if (!SETTINGS.ARCADE_MODE) {
      dpadTag.style.display = "none";
      return false;
    }

    if (dpadTag) {
      this.input.parentElement.style.display = "none";

      this.dpadInput = new DpadInput(dpadTag, {
        screen: this, 
        onValidate: (name)=>{
          this.input.value = name;
          this.switchLabel();
          this.validate();
        }
      });
    }

  }
  onDirectionnal (direction) {
    if (this.dpadInput) {
      this.dpadInput.onDirectionnal(direction);
    }
  }
  onEnter (event) {
    if (this.dpadInput) {
      this.dpadInput.onEnter();
    }
  }
}

export default RecordScreen;