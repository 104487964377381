import Screen from "./Screen";
import SETTINGS from '../Settings';
import STATE from '../State';
import Timer from '../runner/helpers/Timer';
import base64 from 'base64-encode-decode';
import ClipboardJS from 'clipboard';

const OPTIONS = {
  max_scores: 100
};

class HighScoreScreen extends Screen{
  constructor (id, screensManager) {
    super(id, screensManager);

    this.boardsContainer = this.element.querySelector('.highscores-table');

    this.scoreTableBody = this.element.querySelector('.scores-table--body');

    this.list = this.element.querySelector('#scores-table-list');
    this.challengeList = this.element.querySelector('#scores-table-list-challenge');
  
    if (!SETTINGS.FB_ACTIVE) {
      this.bindEvents();
      this.setupTournamentButton();
    }

    this.scrollY = 0;

    this.setCopyLink();

    if (SETTINGS.ARCADE_MODE) {
      this.getScore((response)=>{
        this.screensManager.app.challenge.arcadeChallenger(response);
      }); 
    };

    this.hideChallengeButton();
  }
  open (callback) {
    super.open(callback);

    if (!SETTINGS.FB_ACTIVE) {
      this.getScore();
    }
  }
  getScore (callback = false) {
    const score = STATE.score;
    
    let url = SETTINGS.DB_URL + '/getscores.php';

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    xhr.onload = () => {
      if (xhr.status === 200) {

        if (!callback) {
          this.onLoad(xhr.responseText);
        } else {
          callback(xhr.responseText);
        }
      } else if (xhr.status !== 200) {
        // this.close();
      }
    };

    xhr.send(null);
  }
  onLoad (response) {
    let result = JSON.parse(response);

    if (SETTINGS.ARCADE_MODE) {
      this.screensManager.app.challenge.arcadeChallenger(response);
    };

    if (result.error) {
      this.close();
    } else {
      this.updateLeaderboards({
        global: result.scores,
        challenge: []
      });
    }
  }
  fillTable (table = "global", scores) {

    const list = table === "global" ? this.list : this.challengeList;
    let lineTag = null;
    this.scrollY = 0;

    while (list.firstChild) {
      list.removeChild(list.firstChild);
    }

    scores.forEach ((line, index) => {
      line.position = index+1;
      lineTag = null;

      if( index < OPTIONS.max_scores ) {
        lineTag = this.getRow(line);
        list.appendChild(lineTag);
      }

      if (parseInt(line.id, 10) === parseInt(STATE.pid, 10)) {        
        if (!lineTag) {
          this.outListTag = this.getOutScoreList(line);
          
          lineTag = this.getRow(line);
          this.outListTag.prepend(lineTag);

          if (table === "global") {
            this.scoreTableBody.prepend(this.outListTag);            
          }

        } else {
          this.scrollY = (index - 3) * 35;
        }

        lineTag.classList.add('is-player');
      }
    });

    this.scrollTo(this.scrollY);
  }
  scrollTo (scrollY) {
    this.scrollY = scrollY;

    if (SETTINGS.PLATFORM.INAPP.isMobile) {
      this.element.querySelector('.ps-scrollable').scrollTop = this.scrollY;
    } else {
      this.updateScrollBar(this.psScrollBars[0], this.scrollY);
    }
  }
  getOutScoreList (line) {
    const list = document.createElement("ul");
    list.classList.add('scores-table__table');
    list.classList.add('scores-table__table--outscores');

    return list;
  }
  getTitleRow (params) {
    const li = document.createElement("li");
    li.classList.add('scores-table__row--title');
    const title = document.createElement("div");
    title.classList.add('cell');    
    title.textContent = params.title;
    li.appendChild(title);
    return li;
  }
  getRow (line) {
    const data = ['position', 'name', 'time', 'score'];
    const li = document.createElement("li");

    li.classList.add('scores-table__row');

    if (line.time) {
      line.time = Timer.humanize(line.time);
    }

    data.forEach((key) => {
      const el = document.createElement("div");
      el.classList.add('cell');
      el.classList.add('cell--' + key);
      el.textContent = line[key];

      li.appendChild(el);

      if (key === "position") {
        el.appendChild(document.createElement('sup'));
      }
    });

    return li;

  }
  updateLeaderboards (boards) {
    if (this.outListTag) { 
      this.scoreTableBody.removeChild(this.outListTag);
      this.outListTag = null;
    }

    if (boards.challenge.length > 0) {
      this.fillTable('challenge', boards.challenge);
      this.boardsContainer.classList.add('is-challenge-mode');
    } else {
      this.boardsContainer.classList.remove('is-challenge-mode');
    }

    this.fillTable('global', boards.global);
  }
  //challenge
  bindEvents () {

    this.challengeBtn = this.element.querySelector('#challenge-friend-button');
    this.challengeInput = document.querySelector("#challenger-link");

    if ( this.challengeBtn && this.challengeInput ) {
      this.setupChallenge();
    }
  }
  setupChallenge () {

    this.challengeBtn.addEventListener('click', ()=>{
      this.onClickChallenge();
    });

    this.challengeInput.addEventListener('click', () => {
      this.challengeInput.setSelectionRange(0, this.challengeInput.value.length);
    });

    //prevent writing
    this.challengeInput.addEventListener('input', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.challengeInput.value = this.challengeUrl;
      return false;
    });
  }
  onClickChallenge () {
    this.challengeUrl = window.location.origin;

    let score = STATE.lastRecord.score.toString();
    score += SETTINGS.SECURITY_SLUG;
    score = score.split("");
    score = score.reverse();
    score = score.join("");

    this.challengeUrl += "?tm=" 
      + encodeURIComponent(base64.base64Encode( STATE.lastRecord.name ))
      + "&dpe=" + encodeURIComponent(base64.base64Encode( score ) )
      + "&avs=" + encodeURIComponent(base64.base64Encode(STATE.lastRecord.score.toString()));
  
    this.challengeInput.value = this.challengeUrl;

    if (this.copyBtn) {
      this.copyBtn.classList.remove('is-copied');
    }

    this.screensManager.open('challenge', false);

  }
  onDirectionnal (direction) {
    switch(direction) {
      case "up":
        this.scrollTo(this.scrollY - 35);
        break;
      case "down":
        this.scrollTo(this.scrollY + 35);
        break;
    }
  }
  close (callback) {
    if (!super.close(callback)) {
      return false;
    }

    if (SETTINGS.ARCADE_MODE) {
      if (STATE.parties >= SETTINGS.ARCADE_MAX_PARTIES) {
        this.screensManager.app.reload();
      }
    }
  }
  setCopyLink () {
    this.copyBtn = document.querySelector('.btn--copy');

    if (!this.copyBtn) return false;

    new ClipboardJS('.btn--copy');

    this.copyBtn.addEventListener('click', () => {
      this.copyBtn.classList.add('is-copied');
    });

  }
  setupTournamentButton () {
    let params = {};

    window.location.search.slice(1).split('&').map((option) => {
      const keyValue = option.split('=');
      params[keyValue[0]] = decodeURIComponent(keyValue[1]);
    });

    // if (params.disable_tournament || window.location.hostname === "wimbledon.ralphlauren.eu") {
    //   const btn = this.element.querySelector('.btn--tournament');

    //   if (btn) {
    //     btn.parentElement.classList.add('no-tournament');
    //     btn.parentElement.removeChild(btn); 
    //   }

    //   [].forEach.call(document.querySelectorAll('[data-eu-href]'), function(el) {          
    //     el.setAttribute('href', el.getAttribute('data-eu-href'));
    //   });
    // }
  } 

  hideChallengeButton () {
    const stopDate = new Date('2019-07-11T00:00:00');
    const now = Date.now();
    const button = this.element.querySelector('.btn--tournament');

    if (now > stopDate && button) {
      button.parentElement.removeChild(button);
      this.element.querySelector('.screen__ctas').classList.add('no-tournament');
    }
  }
}


export default HighScoreScreen;