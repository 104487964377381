import { TweenLite } from "gsap/TweenMax";
import SETTINGS from "../Settings";
import Screen from "./Screen";

export default class TutorialScreen extends Screen {
  constructor (id, screensManager) {
    super(id, screensManager);

//    this.delay = 1.5;
    this.delay = 1.5;

    this.index = 0;

    this.cards = this.element.querySelectorAll('.tutorial__case');

  }
  slide () {
    if (!this.opened) return;

    if(this.cards[this.index]) {
      this.cards[this.index].classList.remove('is-active');
    }

    this.index = this.index < this.cards.length-1 ? this.index+1 : 0;

    this.cards[this.index].classList.add('is-active');

    setTimeout(()=>{
      this.slide();
    }, this.delay * 1000);

  }
  close (callback) {
    if (!super.close(callback)) {
      return false;
    }
  }
  open (callback = null) {
    if (!super.open(callback)) return null;
    
    this.index = 0;

    setTimeout(()=>{
      this.slide();
    }, this.delay * 1000);
  } 
};