import OrbitControls from "three-orbitcontrols";

export default class OrbitController {
  constructor (camera, renderer) {
    this.camera = camera;
    this.renderer = renderer;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);


    this.controls.enableZoom = false;
    this.controls.enableDamping = false;
  }
}