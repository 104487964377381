import SETTINGS from "../Settings";
import STATE from "../State";
import { Line } from "three";
import shareImg from './shareImage';


export default class FacebookGame {
  constructor (app, options = {}) {

    this.app = app;

    this.active = SETTINGS.FB_ACTIVE;

    this.state = {
      contextId: null,
      contextType: null,
      playerName: null,
      playerPic: null,
      playerId: null,
      leaderboard: null,
      entries: []
    };

    if ( this.active ) {
      this.init(options.onReady);
    } else {
      options.onReady();
    }

  }
  init (callback) {
    const self = this;
    FBInstant.initializeAsync()
      .then(function(){
        callback();
        self.bindEvents();
      });

    this.highscoreScreen = this.app.screensManager.screens.highscores;
    
    document.body.classList.add('facebook-instant-game');
  }
  loading (progress) {
    if (!this.active) return;

    
    FBInstant.setLoadingProgress(progress);
  }
  start () {
    if (!this.active) return;

    FBInstant.startGameAsync()
      .then(() => {
        
        this.state.contextId = FBInstant.context.getID();
        this.state.contextType = FBInstant.context.getType();

        this.state.playerName = FBInstant.player.getName();
        this.state.playerPic = FBInstant.player.getPhoto();
        this.state.playerId = FBInstant.player.getID();

        STATE.pid = this.state.playerId;

        this.getLeaderBoard();

      }).catch(function(error){
        console.log(error);
      });
  }
  getLeaderBoard () {
    if (!this.active) return false;

    this.getLeaderBoards();
  }
  saveScoreToLeaderBoard (score) {
    if (!this.active) return false;

    this.saveScore(score, SETTINGS.LEADERBOARD_NAME, ()=>{
      if (this.state.contextId) {
        this.saveScore(score, SETTINGS.CONTEXT_LEADERBOARD_NAME + '.' + this.state.contextId, ()=>{
          this.getLeaderBoards();
        });
      } else {
        this.getLeaderBoards();
      }
    });
  }
  saveScore (score, leaderboard, callback) {
    if (!this.active) return false;

    FBInstant.getLeaderboardAsync(leaderboard)
      .then((leaderboard) => {
        return leaderboard.setScoreAsync(score, '{"time": "'+STATE.time+'"}');
      })
      .then((entry) => {
        STATE.pid = entry.getPlayer().getID();

        if (callback) {
          callback();
        }
      })
      .catch(function(error){
        console.log(error);
      });
  }
  getLeaderBoards () {
    const boards = {};

    FBInstant.getLeaderboardAsync(SETTINGS.LEADERBOARD_NAME)
      .then((leaderboard) => {
        return leaderboard.getEntriesAsync();
      })
      .then((globalEntries) => {
        boards.globalEntries = globalEntries;
        
        if (!this.state.contextId) {
          return this.updateBoard(boards);
        }

        FBInstant.getLeaderboardAsync(SETTINGS.CONTEXT_LEADERBOARD_NAME + '.' + this.state.contextId)
          .then((leaderboard)=>{
            return leaderboard.getEntriesAsync();
          })
          .then((contextEntries) => {
            boards.contextEntries = contextEntries;

            return this.updateBoard(boards);
          }).catch(function(error){
            console.log(error);
          });

      }).catch(function(error){
        console.log(error);
      });
  }
  updateBoard (boards) {
    if (!this.active) return false;

    this.highscoreScreen.updateLeaderboards({
      challenge: this.getLines(boards.contextEntries),
      global: this.getLines(boards.globalEntries)
    });

  }
  getLines (entries) {
    if (!entries) return [];

    const lines = [];

    entries.forEach((entry) => {

      let player = entry.getPlayer();
  
      let data = entry.getExtraData();
      data = JSON.parse(data);

      lines.push({
        id: player.getID(),
        position: entry.getRank(),
        name: player.getName(),
        time: data.time,
        score: entry.getFormattedScore()
      });
    });

    return lines;
  }
  share () {  
    if (!this.active) return false;
    

    if (FBInstant.context) {
      console.log(FBInstant.context.getID());

      FBInstant.context.chooseAsync().then((response) => {
        console.log('change context');
        this.updateContext();
      });
    } else {
      
      FBInstant.shareAsync({
        intent: 'SHARE',
        image: shareImg,
        text: 'Game, Set, Match!',
      }).then((response) => {
        this.updateContext();
      });
    }
  }
  updateContext () {
    if (FBInstant.context) {
      console.log(FBInstant.context.getID());

      this.state.contextId = FBInstant.context.getID();
      this.state.contextType = FBInstant.context.getType();

      this.getLeaderBoard();
    }
  }

  bindEvents () {
    document.querySelector("#challenge-friend-button").addEventListener('click', () => {
      this.share();
    });

    FBInstant.onPause(() => {
      console.log('Pause event was triggered!');
      this.app.runner.pause();
    });
  }

  get leaderboardName () {
    if (this.state.contextId) {
      return SETTINGS.CONTEXT_LEADERBOARD_NAME + '.' + this.state.contextId;
    } else {
      return SETTINGS.LEADERBOARD_NAME;
    }
  }
};
