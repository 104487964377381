export default {
  "src": [
    "src/assets/audiosprites/audiosprite.ogg",
    "src/assets/audiosprites/audiosprite.m4a",
    "src/assets/audiosprites/audiosprite.mp3",
    "src/assets/audiosprites/audiosprite.ac3"
  ],
  "sprite": {
    "321": [
      0,
      2000
    ],
    "collision_female": [
      3000,
      348.8888888888888
    ],
    "collision_male": [
      5000,
      325.9863945578232
    ],
    "crowd_cheers": [
      7000,
      2000
    ],
    "crowd_constant": [
      10000,
      6000
    ],
    "crowd_oh": [
      17000,
      2000
    ],
    "dash_female": [
      20000,
      375.0113378684823
    ],
    "dash_male": [
      22000,
      375.0113378684823
    ],
    "go": [
      24000,
      2000
    ],
    "jump_end_female": [
      27000,
      250
    ],
    "jump_end_male": [
      29000,
      250
    ],
    "jump_start_female": [
      31000,
      250
    ],
    "jump_start_male": [
      33000,
      187.50566893424292
    ],
    "lets_go": [
      35000,
      2000
    ],
    "pickup": [
      38000,
      562.5170068027217
    ],
    "pickup_gold": [
      40000,
      1000
    ],
    "pickup_trophy": [
      42000,
      1500
    ],
    "select_player": [
      45000,
      812.5170068027217
    ],
    "start_button": [
      47000,
      1014.4217687074856
    ],
    "theme_1": [
      50000,
      32000
    ],
    "theme_2": [
      83000,
      65000
    ],
    "theme_3": [
      149000,
      16695.66893424036
    ]
  }
};