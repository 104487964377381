export default {
  options : {
    mute: false
  },
  character: {
    id: 1,
    name: 'male',
    json: 'maleJson'
  },
  score: 400,
  time: 10,
  pid: -1, // -1
  parties: 0,
  lastRecord: {
    pid: -1, //-1
    name: 'bot',
    score: 0
  }
};