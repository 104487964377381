import UTILS from "./Utils";

class Timer {
  constructor(game){
    
    this.game = game;

    this.startTime = new Date(0);

    this.time = new Date(0);

    this.text = '';
  
    this.pausedTime = new Date(0);

    this.fps = 60;
    this.times = [];
  }
  start () {
    this.startTime = new Date();
  }
  clock () {

    this.time = new Date(new Date() - this.startTime);

    this.text = UTILS.addLeadingZero(this.time.getMinutes(), 2);
    this.text += ':' + UTILS.addLeadingZero(this.time.getSeconds(), 2);
    this.text += '.' + UTILS.addLeadingZero(this.time.getMilliseconds(), 3);

    // this.updateFPS();
  }
  pause () {
    this.pausedTime = new Date();
  }
  resume () {
    this.startTime = new Date(new Date() - this.time);
  }
  updateFPS () {
    const now = performance.now();

    while (this.times.length > 0 && this.times[0] <= now - 1000) {
      this.times.shift();
    }
    this.times.push(now);
    this.fps = this.times.length || 1;

  }
  static humanize (time) {
    time = new Date(parseInt(time));

    let text = UTILS.addLeadingZero(time.getMinutes(), 2);
    text += ':' + UTILS.addLeadingZero(time.getSeconds(), 2);
    text += '.' + UTILS.addLeadingZero(time.getMilliseconds(), 3);
 
    return text;
  }
}

export default Timer;