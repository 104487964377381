import SETTINGS from "../../Settings";

export default class CameraStory {
  constructor (game, camera, app) {
    
    this.game = game;
    
    this.camera = camera;

    this.blocked = true;
    
    this.app = app;

    this.target = {
      x: 0, 
      y: 0, 
      z: 0
    };

  }
  updateTarget () {

    this.camera.lookAt(this.target.x, this.target.y, this.target.z);
  
  }
  placeCamera () {
    this.camera.position.z = 3;
    this.camera.position.y = 1.2;
    
  }
  placeCameraMenu () {
    this.camera.position.z = 0;
    this.camera.position.y = 1.2;

    this.camera.lookAt(0, 0, -0.4);
  }
  intro (settings = {replay: false}) {

    this.blocked = true;

    let z = 0;
    this.target.z = -0.4;

    if (SETTINGS.LARGE_FOV) {
      TweenLite.to(this.camera.position, 3, {z: 2.5, y: 1});
      
      z = 1; 

    } else {
      TweenLite.to(this.camera.position, 3, {z: 3, y: 1.2}); 
    }

    TweenLite.to(this.target, 3, {z, onUpdate: ()=>{
      this.updateTarget();
      
      if (settings.replay) {
        this.camera.position.x = 0;
      } else {
        this.game.world.hideLogo();
      }
    }, onComplete: () => {
      this.blocked = false;
    }});

  }
  outro () {
    this.blocked = true;

    this.app.screensManager.open('gameover');

    TweenLite.to(this.camera.position, 3, {z: 1, y: 1.2, onComplete: ()=>{
    }}); 

  }
}