import { Ball, BallGold, Chair,  Filet, Digits, DigitsSmall, Bench, Trophy } from '.';
import _ from "lodash";
import * as THREE from 'three';
import itemList from './itemList';
import SETTINGS from '../../Settings';

// const BALLS_QTY = 1; //5
const BALLS_QTY = 10; //5
const OBSTACLES_QTY = 8; ///10

const MIN_DISTANCE_DELTA = 5; //in distance
const MAX_DISTANCE_DELTA = 3; //in distance
const MIN_BALL_DISTANCE_DELTA = 1.75; //in distance

const MAX_Z = 3;

const TYPES = {
  ball: BallGold,
  chair: Chair,
  filet: Filet,
  digits: Digits,
  digitsSmall: DigitsSmall,
  bench: Bench,

  defaults: _.flatten([itemList.intro, _.shuffle(itemList.list)])
};

export default class ItemGenerator {
  constructor (game) {
    this.game = game;
    this.scene = game.scene;

    this.state = {
      delta: 0,
      ballDelta: 0,
      distanceDeltaReduction: SETTINGS.PLATFORM.INAPP.isMobile ? 0.0025 : 0.005,
      minDistanceDelta: MIN_DISTANCE_DELTA,
      chairsLength:0,
      digitsLength:0,
      smallDigitsLength:0,
      lastBallRow: 0,
      goldMode: false
    };

    this.originalState = _.assign({}, this.state);

    this.items = [];

    this.ballsCount = 0;

    this.obstaclesCount = 0;

    this.obstacles = [];

    this.balls = [];

    this.deleteQueue = [];

    this.group = new THREE.Group();

    this.generateObstacles();
 
    this.generateBalls();

    this.scene.add(this.group);

  }
  generateObstacles () {
    
    TYPES.defaults.forEach((type) => {
      let item = new TYPES[type](this);
    
      this.obstacles.push( item );
    
      this.group.add(item.sprite);
    });

  }
  generateBalls () {
    
    for (let i=0; i < BALLS_QTY; i ++) {
      const ball = new BallGold(this);
    
      this.balls.push(ball);
    
      this.group.add(ball.sprite);
    }
  
    const trophy = new Trophy(this);
    this.balls.push(trophy);
    this.group.add(trophy.sprite);

  }
  switchGold (goldMode = true) {
    if (this.state.goldMode === goldMode) {
      return false;
    }

    this.state.goldMode = goldMode;

    this.balls.forEach((ball) => {
      ball.switchGold(goldMode);
    });
  }
  drop (type = null, x = null) {
    let item = null;

    if (type === "ball") {

      item = this.balls[0];

      item.reset();

      this.ballsCount += 1;

      this.balls.splice(0,1);

      this.balls.push(item);

      this.state.ballDelta = 0;

      this.state.delta -= 1;

    } else {

      item = this.obstacles[0];

      item.reset();

      this.obstaclesCount += 1;
      
      this.obstacles.splice(0,1);

      this.obstacles.splice(_.random(this.obstacles.length-3, this.obstacles.length), 0, item);
  
      this.state.delta = -item.state.distanceDelay;

      if (!item.state.up) {
        this.state.ballDelta = 0;
      } else {
        this.state.ballDelta = 10;
      }
    }
    
    this.items.push(item);

    // this.group.add(item.sprite);
  }
  update () {
    this.updateItems();
  
    if(this.obstaclesCount < OBSTACLES_QTY 
      && this.state.delta > this.state.minDistanceDelta) {
      this.drop();
    } 
    
    if (this.ballsCount < BALLS_QTY
      && this.state.ballDelta > MIN_BALL_DISTANCE_DELTA) {
      this.drop("ball");
    }

    this.state.minDistanceDelta = Math.max(MAX_DISTANCE_DELTA, this.state.minDistanceDelta - this.state.distanceDeltaReduction); // IN METERS
    this.state.delta += this.game.state.GAME_SPEED;
    this.state.ballDelta += this.game.state.GAME_SPEED;
  }
  updateItems () {
    this.items.forEach((item, index) => {
      item.z += this.game.state.GAME_SPEED;

      if (item.needsUpdate && item.ready) {
        item.update(this.state.delta);
      }

      if (item.z > this.game.player.z - 1 ) {
        if(this.isOut(item)) {
          this.deleteQueue.push(index);          
        } else {
          item.testHit(this.game.player);
        }
      }
    });  

    this.flushQueue();
  }
  isOut (item) {
    if (item.z > MAX_Z) {

      if (item.type === "ball") {
        if (item.state.active) {
          this.game.state.PARTY.resetBonus();                
        }
        this.ballsCount -= 1;
      } else {
        this.obstaclesCount -= 1;
      }

      item.hide();

      return true;
    }  
    return false;
  }
  flushQueue () {    
    if ( this.deleteQueue.length > 0 ) {
      _.pullAt(this.items, this.deleteQueue);
    }

    this.deleteQueue = [];
  }
  empty () {
    this.balls.forEach( (ball) => {
      this.group.remove( ball.sprite );
      ball.destroy();
    });

    this.items = [];

    this.obstacles.forEach( (obstacle) => {
      obstacle.hide();
    });

    this.ballsCount = 0; 
    this.obstaclesCount = 0;

    this.state = _.assign(this.state, this.originalState);
  }
  reset () {
    this.balls.forEach( (ball) => {
      ball.reset();
      ball.hide();
    });

    this.obstacles.forEach( (obstacle) => {
      obstacle.reset();
      obstacle.hide();
    });

    this.items = [];

    this.ballsCount = 0; 
    this.obstaclesCount = 0;

    this.state = _.assign(this.state, this.originalState);

  }
  remove (index) {

    let item = this.items[index];

    if (item.type === "ball") {
      this.ballsCount -= 1;
    } else {
      this.obstaclesCount = 1;
    }

    this.group.remove( item.sprite );
    item.destroy();

    this.items.splice(index, 1);
  }
}