import Item from './Item';
import SETTINGS from '../../Settings';

const IMGS = [
  {
    texture: 'chaise_001',
    scale: 0.9,
    y: 0.41
  },
  {
    texture: 'chaise_arbitre_002',
    scale: 1.1,
    y: 0.49
  },
  {
    texture: 'chaise_arbitre_002',
    scale: 1.1,
    y: 0.49
  }
];

class Chair extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "obstacle";
  }
  setup () {
    const img = IMGS[this.itemGenerator.state.chairsLength % IMGS.length];

    this.scale = img.scale;

    this.create(img.texture);

    this.sprite.position.set(0, img.y, SETTINGS.Z_TRACK_END);

    this.scene.add(this.sprite);
    this.polishTexture();

    this.itemGenerator.state.chairsLength += 1;
  }
};

export { Chair };