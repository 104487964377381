import SETTINGS from "../../Settings";
import TweenLite from "gsap/TweenLite";

const STEPS = [
  1000,
  3000,
  5000
];

export default class Party {

  constructor (game) {
    this.game = game;

    this.SCORE = 0;

    this.BONUS = 1;

    this._bonus = 1;

    this._step = STEPS[0];

    this._stepIndex = 0;

    this.MAX_BONUS = 9;

    this.ELAPSE_BONUS = 2500;

    this.scoreTag = document.getElementById("interface").querySelector('.hud--score');

    this.bonusTag = document.getElementById("bonus-ball");

    this.bonusGauge = this.bonusTag.querySelector(".hud--bonus__gauge");

    this.bonusTween = TweenLite.set({a:0});
  }

  addPoint (score = 0) {

    let point = score * this.BONUS;


    this._bonus = Math.min(this.MAX_BONUS, this._bonus + 0.5);

    this.BONUS = Math.floor(this._bonus);

    this.bonusTag.style.fontSize = (10 + this.BONUS) + "px";

    this.bonusTween.kill();

    if ( this.BONUS === this.MAX_BONUS ) {
      point = Math.round((point * 1.66) / 10) * 10;

      this.game.itemGenerator.switchGold(true);
    }

    this.SCORE += point;


    // this.bonusTween = TweenLite.fromTo(this.bonusGauge, this.ELAPSE_BONUS / 1000, {height: "100%"}, {height: "0%", onComplete:() => {
    this.bonusTween = TweenLite.to({a:0}, this.ELAPSE_BONUS / 1000, {a: 1, onComplete:() => {
      this.resetBonus();
    }});

    this.checkStep();

    this.animatePoint(point);

  }
  animatePoint (point) {
    const pointTag = document.createElement("span");
    
    pointTag.innerText = "+" + point;
    
    this.scoreTag.appendChild(pointTag);

    TweenLite.to({a:0}, .5, {a:1, onComplete: ()=>{
      this.scoreTag.removeChild(pointTag);
    }});
  }
  checkStep () {
    if (this.SCORE > this._step) {
      this.game.app.audio.upCrowd();
      this.game.app.audio.play('crowdCheers');

      this._stepIndex += 1;
    
      if (STEPS.length - 1 > this._stepIndex ) {
        this._step = STEPS[this._stepIndex];
      } else {
        this._step = STEPS[STEPS.length - 1] + 5000 * (this._stepIndex - (STEPS.length-1)); 
      }      
    }
  }
  resetBonus () {
    this.BONUS = 1; 
    this._bonus = 1;
    this.bonusTag.style.fontSize = (10 + this.BONUS) + "px";
    this.bonusTag.classList.remove("max-bonus");  
    this.game.itemGenerator.switchGold(false);
    this.game.app.audio.downCrowd();

  }
  
  reset () {
    this.SCORE = 0;
    this.BONUS = 1;
    this._bonus = 1;    
    this._step = STEPS[0];

    this.resetBonus();
  }

} 