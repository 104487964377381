import Item from './Item';
import SETTINGS from '../../Settings';
import _ from 'lodash';
import * as THREE from 'three';

const POSITION = {
  x: 0,
  y: 0.24, //up : 1.1
  z: 0
};


class Trophy extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "ball";

    this.sprite.visible = true;

    this.x = Math.round(_.random(-SETTINGS.TRACKS/2, SETTINGS.TRACKS/2)) * SETTINGS.TRACK_WIDTH;

    // this.z = SETTINGS.Z_TRACK_END;
  }
  setup () {

    this.scale = 0.5;

    this.create("trophy");

    // this.scene.add(this.sprite);

    this.sprite.position.set(POSITION.x, POSITION.y, SETTINGS.Z_TRACK_END);

    this.polishTexture();

    this.material.opacity = 1;
  }
  testHit (player) {
    // if ( this.testX(player.x) && this.testZ(player.z) && this.state.active && player.state.jumping) {
    if ( this.testX(player.x) && this.testZ(player.z) && this.state.active) {
      player.grab(SETTINGS.TROPHY_SCORE, 'trophy');
      this.hide();
    }
  }
  switchGold () {
    return false;
  }
  testX (xPosition) {
    return Math.abs(this.x - xPosition) < 0.2;
  }
  testZ (zPosition) {
    return Math.abs(this.z - zPosition) < 0.3;
  }
  reset () {
    this.show();

    const minRow = Math.max(0, this.itemGenerator.state.lastBallRow - 1);
    
    const maxRow = Math.min(SETTINGS.TRACKS - 1, this.itemGenerator.state.lastBallRow + 1);

    this.row = _.random(minRow, maxRow);

    this.x = (this.row - (SETTINGS.TRACKS-1)/2) * SETTINGS.TRACK_WIDTH;

    this.itemGenerator.state.lastBallRow = this.row;

    this.z = SETTINGS.Z_TRACK_END;
  }
};

export { Trophy };