import * as THREE from 'three';
import _ from 'lodash';

const POSITION = {
  x: 0,
  y: 0.28,
  z: 2.3
};


const OPTIONS = {
  scale: 0.4
};

export default class FXSprite {
  constructor (params) {
    this.frameDelay = 4;
    
    this.ready = false;

    this._index = 0;

    this.group = new THREE.Group();

    this.slug = params.slug;

    this.game = params.game;

    this.scene = params.scene;

    this.delay = params.delay;

    POSITION.y = params.y;

    this.scale = params.scale;

    this.tilt = -this.delay;

    this._textures = [];

    this.anim = null;

    this.defaultAnim = null;

    this.json = JSON.parse(this.game.FILES.fxJson);

    this.sourceSize = {w:0, h:0};

    this.needsUpdate = false;

    this.create();

    this.addToScene();
  }
  create () {
    this.texture = this.game.TEXTURES.fx.clone();
    this.texture.needsUpdate  = true;
    this.material = new THREE.SpriteMaterial( { map: this.texture, color: 0xffffff, transparent: true } );
    this.sprite = new THREE.Sprite(this.material);
    this.group.add(this.sprite);

    this.anim = {
      slug: this.slug
    };

    this.generate(this.anim);

    this.ready = true;

    this.sprite.visible = false;
  }
  generate (animation) {
    animation.frames = [];
    
    _.keys(this.json.frames).forEach( (key, i) => {
      let frame = this.json.frames[key];
      let slug = frame.filename.substring(0, frame.filename.length - 4);
      
      if (animation.slug === slug ) {
        animation.frames.push(frame.frame);
      }

      this.sourceSize = frame.sourceSize;
    }, this);

    this.sprite.scale.set(this.sourceSize.w / this.sourceSize.h * this.scale, this.scale, 1);
  }
  setFrame (frame) {
    const image = this.texture.image;
    this.texture.repeat.set(frame.w / image.width, frame.h / image.height);
    this.texture.offset.x = ((frame.x) / image.width);
    this.texture.offset.y = 1 - (frame.h / image.height) - (frame.y / image.height);
  }
  animate () {
    if (this._index < this.anim.frames.length - 1) {
      this._index += 1;
    } else {
      this._index = 0;
      this.needsUpdate = false;
      this.sprite.visible = false;
    }
    
    this.setFrame(this.anim.frames[this._index]);
  }
  update (x) {
    this.tilt += 1;

    this.x = x;

    if (this.tilt >= this.frameDelay && this.ready) {
      this.tilt = 0;
      this.sprite.visible = true;
      this.animate();
    }
  }
  addToScene () {
    this.scene.add(this.group);
    this.sprite.position.set(POSITION.x, POSITION.y, POSITION.z);
  }
  destroy () {
    this.texture.dispose();
    this.material.dispose();
    this.group.remove(this.sprite);
    this.scene.remove(this.group);
  }
  play () {
    this.needsUpdate = true;
    this._index = 0;
    this.tilt = -this.delay;
  }
  get x () {
    return this.sprite.position.x;
  }
  set x (_x) {
    this.sprite.position.x = _x;
  }
  get y () {
    return this.sprite.position.y;
  }
  set y (_y) {
    this.sprite.position.y = _y;
  }
  set z (_z) {
    this.sprite.position.z = _z;
  }
  get z () {
    return this.sprite.position.z;
  }

}