import { TextureLoader, FileLoader } from 'three';
import AtlasTexture from '../sprite/AtlasTexture';
import SETTINGS from '../../Settings';

const LoaderMessages = [
  'Entering the Court...',
  'Warming Up...',
  "Let’s Go!"
];

export default class Preloader {
  constructor ({game = {}, onEnd = ()=>{}}) {
    this.game = game;

    this.onEnd = onEnd;

    this.percent = 0;

    this.loaderElement = document.getElementById('loader-percent');
    this.loaderMessageElement = document.getElementById('loader-message');

    this.queue = [
      ['bands', 'src/assets/textures/bands_v1.jpg'],
      ['clouds', 'src/assets/textures/clouds_v1.png'],
      ['people1', 'src/assets/textures/pnj_003.png'],
      ['people2', 'src/assets/textures/pnj_002.png'],
      ['people3', 'src/assets/textures/pnj_001.png'],

      ['paper', 'src/assets/textures/texture-paper.jpg'],
      ['tracks', 'src/assets/textures/tracks-huge_5.jpg'],
      ['green', 'src/assets/textures/green_1.jpg'],
      
      //SPRITESHEETS
      ['elementsSpritesheet', 'src/assets/spritesheets/elements.png'],
      ['elementsJson', 'src/assets/spritesheets/elements.json', 1],

      ['female0Sprite', 'src/assets/spritesheets/female_all-0.png'],
      ['female1Sprite', 'src/assets/spritesheets/female_all-1.png'],
      ['female2Sprite', 'src/assets/spritesheets/female_all-2.png'],
      
      ['female0Json', 'src/assets/spritesheets/female_all-0.json', 1],
      ['female1Json', 'src/assets/spritesheets/female_all-1.json', 1],
      ['female2Json', 'src/assets/spritesheets/female_all-2.json', 1],
      

      ['male0Sprite', 'src/assets/spritesheets/male_all-0.png'],
      ['male1Sprite', 'src/assets/spritesheets/male_all-1.png'],
      ['male2Sprite', 'src/assets/spritesheets/male_all-2.png'],
      
      ['male0Json', 'src/assets/spritesheets/male_all-0.json', 1],
      ['male1Json', 'src/assets/spritesheets/male_all-1.json', 1],
      ['male2Json', 'src/assets/spritesheets/male_all-2.json', 1],

      ['fx', 'src/assets/spritesheets/dust_alpha.png'],
      ['fxJson', 'src/assets/spritesheets/dust.json', 1],
    ];

    this.index = 0;

    this.textureLoader = new TextureLoader();
    this.fileLoader = new FileLoader();

    this.TEXTURES = {};
    this.FILES = {};

    this.load(this.queue[this.index]);
  }
  load (asset) {

    const loader = typeof asset[2] === 'undefined' ? this.textureLoader : this.fileLoader;
    const PACK = typeof asset[2] === 'undefined' ? this.TEXTURES : this.FILES;

    loader.load(asset[1], (result) => {
      this.percent = (this.index+1)/this.queue.length * 100;

      if (SETTINGS.FB_ACTIVE) {
        this.game.app.FBGame.loading(this.percent);
      } else {
        this.updateLoader(this.percent);
      }

      PACK[asset[0]] = result;
      
      if (this.index === this.queue.length - 1) {
        
        this.generateAtlas();

      } else {

        this.index++;
        this.load(this.queue[this.index]);
      
      }
    });
  }
  updateLoader (percent) {


    this.loaderElement.innerText = Math.round(percent);

    if (percent < 50) {
      this.loaderMessageElement.innerText = LoaderMessages[0];
    } else if(percent < 99) {
      this.loaderMessageElement.innerText = LoaderMessages[1];
    } else {
      this.loaderMessageElement.innerText = LoaderMessages[2];
    }
  }
  generateAtlas () {
    this.TEXTURES.elementsAtlas = new AtlasTexture(this.FILES.elementsJson, this.TEXTURES.elementsSpritesheet);

    this.end();
  }
  end () {
    this.game.app.FBGame.start();

    this.game.app.screensManager.closeLoader();

    this.onEnd(this.TEXTURES, this.FILES);
  }
}
