import Item from './Item';
import SETTINGS from '../../Settings';
import _ from "lodash";

const IMGS = [
  {
    texture: 'tableau_003',
    scale: {x:0.6, y: 1.1},
    y: 0.5
  },
  {
    texture: 'tableau_004',
    scale: {x:0.6, y: 1.1},
    y: 0.5
  },
  {
    texture: 'tableau_005',
    scale: {x:0.6, y: 1.1},
    y: 0.5
  },
  {
    texture: 'tableau_006',
    scale: {x:0.6, y: 1.1},
    y: 0.5
  }
  
];

class DigitsSmall extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "obstacle";

    this.state = {
      up: true,
      rows: 1,
      distanceDelay: 0
    };

  }
  setup () {
    this.scale = 1.1;

    const img = IMGS[this.itemGenerator.state.smallDigitsLength % IMGS.length];

    this.create(img.texture);

    this.sprite.position.set(0, img.y, SETTINGS.Z_TRACK_END);

    this.scene.add(this.sprite);
    this.polishTexture();

    this.itemGenerator.state.smallDigitsLength += 1;
  }
  testHit (player) {
    if ( this.testX(player.x) && this.testZ(player.z) && !player.state.crouching) {
      player.hit(this);
    }
  }
}

export { DigitsSmall };
