import * as THREE from 'three';
import SETTINGS from '../Settings';
import People from './world/People';
import { TweenMax, Linear } from 'gsap';

export default class World  {
  constructor (game) {
    this.game = game;
    this.scene = game.scene;

    this.addFloor();

    this.addPlaneFloor();
    // this.addPlanet();


    if (SETTINGS.PUBLIC) {
      this.addPeople2();
      this.addBorder();

      // this.addPeople();
    }

    this.addSky();

    this.addLogo();
    
  }
  addFloor () {

    const spriteMap = this.game.TEXTURES.tracks;
    spriteMap.wrapS = THREE.RepeatWrapping; 
    spriteMap.wrapT = THREE.RepeatWrapping;

    // spriteMap.repeat.set(1, 200);
    spriteMap.repeat.set(1, 4);
    
    if (SETTINGS.ANISOTROPY) {
      const maxAnisotropy = this.game.renderer.capabilities.getMaxAnisotropy();
      spriteMap.anisotropy = maxAnisotropy;  
    }

    spriteMap.magFilter = THREE.NearestFilter;
    spriteMap.minFilter = THREE.NearestFilter;
     
    const geometry = new THREE.PlaneBufferGeometry( SETTINGS.TRACK_WIDTH * SETTINGS.TRACKS, 26.5, 1 );
    this.floorMaterial = new THREE.MeshBasicMaterial({map: spriteMap, color: 0xffffff});
    this.floor = new THREE.Mesh( geometry, this.floorMaterial );   
    this.floor.rotation.x = -Math.PI / 2;
    this.floor.position.z = -1;

    // spriteMap.minFilter = THREE.NearestFilter;
    // spriteMap.minFilter = THREE.LinearFilter;
    // spriteMap.magFilter = THREE.NearestFilter;
    // spriteMap.magFilter = THREE.NearestFilter;

    this.scene.add(this.floor);

  } 
  addPlaneFloor () {
    const spriteMap2 = this.game.TEXTURES.green;
    spriteMap2.wrapS = THREE.RepeatWrapping; 
    spriteMap2.wrapT = THREE.RepeatWrapping;
    // spriteMap2.repeat.set(30,30);
    // spriteMap2.repeat.set(4,16);
    // spriteMap2.repeat.set(10,60);
    spriteMap2.repeat.set(10,60);
    // spriteMap2.repeat.set(16,96);
     
    const geometry2 = new THREE.PlaneBufferGeometry( 5, 30, 1 );
    this.floorMaterial2 = new THREE.MeshBasicMaterial({map: spriteMap2, color: 0xffffff});
    this.floor2 = new THREE.Mesh( geometry2, this.floorMaterial2 );
    this.floor2.rotation.x = -Math.PI / 2;
    this.floor2.position.y = -0.01;
    this.floor2.position.z = -1;

    // spriteMap2.magFilter = THREE.NearestFilter;
    // spriteMap2.minFilter = THREE.NearestFilter;

    // spriteMap2.minFilter = THREE.LinearFilter;
    // spriteMap2.minFilter = THREE.LinearFilter;
    // spriteMap2.magFilter = THREE.NearestFilter;
    // spriteMap2.magFilter = THREE.NearestFilter;

    this.scene.add(this.floor2);
  }
  addPlanet () {
    const spriteMap2 = this.game.TEXTURES.green;
    spriteMap2.wrapS = THREE.RepeatWrapping; 
    spriteMap2.wrapT = THREE.RepeatWrapping;
    spriteMap2.repeat.set(128, 128);

    this.floorMaterial2 = new THREE.MeshBasicMaterial({map: spriteMap2, color: 0xffffff});

    this.planetGeo = new THREE.SphereBufferGeometry(110, 64, 64 );
    this.planet = new THREE.Mesh(this.planetGeo, this.floorMaterial2);

    this.planet.position.y = -110;
    this.planet.position.z = -11;
    // this.planet.position.z = -14;

    this.planet.rotation.x = Math.PI / 2;
    this.planet.rotation.z = Math.PI / 2;
    this.scene.add(this.planet);
  }
  addBorder () {
    const spriteRight = this.game.TEXTURES.bands.clone();
    spriteRight.wrapS = THREE.RepeatWrapping; 
    spriteRight.wrapT = THREE.RepeatWrapping;
    spriteRight.repeat.set(8,1);
    spriteRight.needsUpdate = true;

    const spriteLeft = this.game.TEXTURES.bands.clone();
    spriteLeft.wrapS = THREE.RepeatWrapping; 
    spriteLeft.wrapT = THREE.RepeatWrapping;
    spriteLeft.repeat.set(8,1);
    spriteLeft.needsUpdate = true;

    spriteRight.minFilter = THREE.LinearFilter;
    spriteLeft.minFilter = THREE.LinearFilter;
    

    // spriteRight.minFilter = THREE.LinearMipMapLinearFilter;
    // spriteLeft.minFilter = THREE.LinearMipMapLinearFilter;
    
    spriteRight.magFilter = THREE.NearestFilter;
    spriteLeft.magFilter = THREE.NearestFilter;

    if ( !SETTINGS.PLATFORM.INAPP.isMobile && SETTINGS.ANISOTROPY) {

      const maxAnisotropy = this.game.renderer.capabilities.getMaxAnisotropy();
      spriteRight.anisotropy = maxAnisotropy;
      spriteLeft.anisotropy = maxAnisotropy;
    }

    const geometry = new THREE.PlaneBufferGeometry( 24.5, 0.6, 1 );
    this.borderLeftMaterial = new THREE.MeshBasicMaterial({color: 0xffffff, map: spriteLeft });
    this.borderRightMaterial = new THREE.MeshBasicMaterial({color: 0xffffff, map: spriteRight });

    this.borderRight = new THREE.Mesh( geometry.clone(), this.borderRightMaterial );   
    this.borderRight.rotation.y = -Math.PI / 2;
    this.borderRight.position.x = 2;
    this.borderRight.position.z = -1;
    this.borderRight.position.y = 0.3;

    this.borderLeft = new THREE.Mesh( geometry, this.borderLeftMaterial );   
    this.borderLeft.rotation.y = Math.PI / 2;
    this.borderLeft.position.x = -2;
    this.borderLeft.position.z = -1;
    this.borderLeft.position.y = 0.3;


    this.scene.add(this.borderLeft);
    this.scene.add(this.borderRight);
  }
  
  addPeople2 () {
    this.peopleLeft = new People(this.game);
    this.peopleRight = new People(this.game, {reversed: true});
  }
  addSky () {
    const texture = this.game.TEXTURES.clouds.clone();
    texture.wrapS = THREE.RepeatWrapping; 
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(4,3);
    texture.magFilter = THREE.NearestFilter;
    texture.needsUpdate = true;

    const geometry = new THREE.PlaneBufferGeometry( 120, 40, 1);
    this.skyMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: texture, transparent: true, opacity: 0.5, fog: false});

    this.sky = new THREE.Mesh( geometry, this.skyMaterial);
    this.sky.position.z = -20;
    this.sky.position.y = 6;

    this.skyMaterial.map.offset.x = 0.3;


    this.scene.add( this.sky );
  }
  addLogo () {
    this.logoGroup = new THREE.Group();

    const size = SETTINGS.LARGE_FOV ? 1 : 0.5;

    const logoTexture = this.texture = this.game.TEXTURES.elementsAtlas.get("logo-beige");
    const logoShadowTexture = this.texture = this.game.TEXTURES.elementsAtlas.get("logo-shadow");

    const logo = new THREE.Mesh(
      new THREE.PlaneBufferGeometry( size, size, 1), 
      new THREE.MeshBasicMaterial({ color: 0xffffff, map: logoTexture, transparent: true})
    );

    const logoShadow = new THREE.Mesh(
      new THREE.PlaneBufferGeometry( size, size, 1), 
      new THREE.MeshBasicMaterial({ color: 0xffffff, map: logoShadowTexture, transparent: true, opacity: 0.6})
    );

    this.logoGroup.add(logo);
    this.logoGroup.add(logoShadow);

    this.logoGroup.rotation.x = -Math.PI/2;
    this.logoGroup.position.y = 0.1;
    this.logoGroup.position.z = -0.4;

    logoShadow.position.z = -0.004;
    logoShadow.position.y = -0.004;

    this.scene.add(this.logoGroup);

    this.logoTween = TweenMax.to([logo.rotation, logoShadow.rotation], 20, {z: Math.PI*2, repeat: -1, ease: Linear.easeNone});
    
    this.logoTween = TweenMax.to([logo.rotation, logoShadow.rotation], 20, {z: Math.PI*2, repeat: -1, ease: Linear.easeNone});
  }
  hideLogo () {

    this.logoGroup.children.forEach((logo)=>{
      TweenMax.to(logo.material, 1, {opacity: 0, onComplete: ()=>{
        this.logoTween.kill();
        this.logoGroup.position.y = -5;
        this.logoGroup.visible = false;
      }});
    });

    // TweenMax.to(this.logoGroup.position, 3, {z: -10});

  }
  update () {
    // return null;

    // this.floorMaterial.map.offset.y += this.game.state.GAME_SPEED/6;
    this.floorMaterial.map.offset.y += this.game.state.GAME_SPEED;
    this.floorMaterial2.map.offset.y += this.game.state.GAME_SPEED;
    // this.floorMaterial2.map.offset.y += this.game.state.GAME_SPEED*10;
   
    if (SETTINGS.PUBLIC) {
      // this.peopleRightMaterial.map.offset.x -= this.game.state.SPEED/4;
      // this.peopleLeftMaterial.map.offset.x += this.game.state.SPEED/4;
      this.peopleLeft.update();
      this.peopleRight.update();

      this.borderRightMaterial.map.offset.x -= this.game.state.GAME_SPEED/4.2;
      this.borderLeftMaterial.map.offset.x += this.game.state.GAME_SPEED/4.2;
    }

    if (this.skyMaterial) {
      this.skyMaterial.map.offset.y -= 0.0005;
    }

    
  }
}