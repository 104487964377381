import Item from './Item';
import SETTINGS from '../../Settings';

const POSITION = {
  x: 0,
  y: 0.3,
  z: 10
};

class Bench extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "obstacle";

    this.state = {
      low: true,
      rows: 2,
      distanceDelay: 0
    };

  }
  setup () {
    this.scale = 0.5;

    this.create("bench");
  
    this.scene.add(this.sprite);

    this.sprite.position.set(POSITION.x, POSITION.y, SETTINGS.Z_TRACK_END);

    this.polishTexture();
  }
  testHit (player) {
    if ( this.testX(player.x) && this.testZ(player.z) && !player.state.jumping) {
      player.hit(this);
    }
  }
  testX (xPosition) {
    return xPosition - 0.1 >= (this.x - SETTINGS.TRACK_WIDTH) && xPosition + 0.1 <= (this.x + SETTINGS.TRACK_WIDTH);
  }
  reset () {
    super.reset();

    this.x = Math.random() > .5 ? -SETTINGS.TRACK_WIDTH/2 : SETTINGS.TRACK_WIDTH/2;  
  }
  
}

export { Bench };
