import * as THREE from 'three';
import _ from 'lodash';

const POSITION = {
  x: 0,
  // y: 0.36,
  y: 0.5,
  z: 2.1
};

// const SCALE = {
//   x: 0.85,
//   y: 1.2,
//   z: 1
// };

const OPTIONS = {
  scale: 0.84
};

export default class AnimatedSprite {
  constructor (params) {
    this.loader = new THREE.FileLoader();

    this.frameDelay = 4;
    
    this.texture = params.texture;

    this.ready = false;

    this._index = 0;

    this.tilt = 0;

    this.group = new THREE.Group();

    this.scene = params.scene;

    this._textures = [];

    this.animations = params.animations;

    this.prefix = params.prefix || '';

    this.anim = null;

    this.defaultAnim = null;

    this.json = JSON.parse(params.json);

    this.sourceSize = {w:0, h:0};

    POSITION.y = params.type === "male" ? 0.57 : 0.5;

    this.create();

    this.addToScene();
  }
  create () {
    this.material = new THREE.SpriteMaterial( { map: this.texture, color: 0xffffff, transparent: true } );
    this.sprite = new THREE.Sprite(this.material);
    this.group.add(this.sprite);

    _.keys(this.animations).forEach( (key, i) => {
      this.generate(this.animations[key]);
    }, this);

    this.ready = true;

    this.animate();
  }
  generate (animation) {
    animation.frames = [];
    
    _.keys(this.json.frames).forEach( (key, i) => {
      let frame = this.json.frames[key];
      let slug = frame.filename.substring(0, frame.filename.length - 4);
      
      if ( this.prefix + animation.slug === slug ) {
        animation.frames.push(frame.frame);
      }

      this.sourceSize = frame.sourceSize;
    }, this);

    animation.delays = this.getFramesDelays(animation);

    if (animation.default) {
      this.anim = animation;

      this.defaultAnim = animation;
    }

    this.sprite.scale.set(this.sourceSize.w / this.sourceSize.h * OPTIONS.scale, OPTIONS.scale, 1);
  }
  getFramesDelays (animation) {
    if (animation.delays) {
      return animation.delays;
    }

    let delays = [];

    for (let i = 0; i < animation.frames.length; i++) {
      delays[i] = animation.delay || this.frameDelay;
    }

    return delays;    
  }
  setFrame (frame) {
    const image = this.texture.image;
    this.texture.repeat.set(frame.w / image.width, frame.h / image.height);
    this.texture.offset.x = ((frame.x) / image.width);
    this.texture.offset.y = 1 - (frame.h / image.height) - (frame.y / image.height);
  }

  animate () {
    if (this._index < this.anim.frames.length - 1) {
      
      this._index += 1;

    } else {

      this._index = 0;

      if (!this.anim.loop) {
        this.anim = this.defaultAnim;
      }
    }
    
    this.setFrame(this.anim.frames[this._index]);
  }
  update (delta) {
    this.tilt += 1;

    if (this.tilt >= this.anim.delays[this._index] && this.ready) {
      this.tilt = 0;
      this.animate();
    }
  }
  addToScene () {
    this.scene.add(this.group);

    // this.sprite.scale.set(SCALE.x, SCALE.y, SCALE.z);
    this.sprite.position.set(POSITION.x, POSITION.y, POSITION.z);
  }
  destroy () {
    this.texture.dispose();
    this.material.dispose();
    this.group.remove(this.sprite);
    this.scene.remove(this.group);
  }
  play (animationName) {

    if (!this.animations[animationName]) {
      console.log('no anim found for :' +animationName);
    } else {
      this._index = 0;
      this.anim = this.animations[animationName];
    }
  }
  get x () {
    return this.sprite.position.x;
  }
  set x (_x) {
    this.sprite.position.x = _x;
  }
  get y () {
    return this.sprite.position.y;
  }
  set y (_y) {
    this.sprite.position.y = _y;
  }
  set z (_z) {
    this.sprite.position.z = _z;
  }
  get z () {
    return this.sprite.position.z;
  }

}