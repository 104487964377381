import { TweenMax } from "gsap/TweenMax";
import SETTINGS from "../Settings";
import Screen from "./Screen";

export default class GameOverScreen extends Screen { 
  constructor (id, screensManager) {
    super(id, screensManager);

    this.tags = this.element.querySelectorAll('.tag__word');
  }
  open (callback = null) {
    if (!super.open(callback)) return null;
   
    TweenMax.staggerFrom(this.tags, 0.45, {width: 0, delay:0.2}, .25, ()=>{
      setTimeout(()=>{
        this.close();
      }, 500);
    });
  }
  close (callback = null) {
    super.close(() => {
      // if (SETTINGS.FB_ACTIVE) {
        // this.screensManager.open('highscores');
      // } else {
        this.screensManager.open('score');
      // }
    });
  } 
};