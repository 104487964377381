export default class DpadInput {
  constructor (element, params) {
    this.element = element;

    this.screen = params.screen;

    this.onValidate = params.onValidate;

    this.index = 0;

    this.letters = this.element.querySelectorAll('.dpad-input__letter');
    
    this.currentLetter = this.letters[0];

    this.ALPHAS = [];

    this.letters.forEach(() => {
      this.ALPHAS.push(this.genCharArray("a", "z"));
    });

    this.validated = false;

    this.name = "";
  }
  onDirectionnal (direction) {
    if ( this.validated ) {
      return false;
    }
    
    switch (direction) {
      case 'left':
        this.onLeft();
        break;
      case 'right':
        this.onRight();
        break;
      case 'up':
        this.onUp();
        break;
      case 'down':
        this.onDown();
        break;
    }

  }
  onLeft () {
    let index = this.index > 0 ? this.index - 1 : this.letters.length - 1;
     
    this.changeLetter(index);
  }
  onRight () {
    let index = this.index < this.letters.length - 1 ? this.index + 1 : 0;
     
    this.changeLetter(index);
  }
  onUp () {
    const alpha = this.ALPHAS[this.index];

    alpha.index = alpha.index > 0 ? alpha.index - 1 : alpha.letters.length -1;

    this.currentLetter.innerText = alpha.letters[alpha.index];
  }
  onDown () {    
    const alpha = this.ALPHAS[this.index];

    alpha.index = alpha.index < alpha.letters.length -1 ? alpha.index + 1 : 0;

    this.currentLetter.innerText = alpha.letters[alpha.index];
  }
  onEnter () {
    if ( this.validated ) {
      return false;
    }

    if (this.index < this.letters.length - 1) {
      this.onRight();
    } else {
      this.validate();
    }
  }
  changeLetter (index) {

    this.currentLetter.classList.remove('is-active');

    this.index = index;

    this.currentLetter = this.letters[this.index];

    this.currentLetter.classList.add('is-active');
    
  }
  validate () {
    this.currentLetter.classList.remove('is-active');

    this.name = "";

    this.ALPHAS.forEach((alpha) => {
      this.name += alpha.letters[alpha.index];
    });
    
    this.validated = true;

    this.onValidate(this.name);
  }
  reset () {
    this.index = 0;

    this.currentLetter.classList.remove('is-active');
    this.currentLetter = this.letters[0];
    this.currentLetter.classList.add('is-active');

    this.validated = false;
  }
  genCharArray(charA, charZ) {
    let a = [];
    let i = charA.charCodeAt(0);
    let j = charZ.charCodeAt(0);

    for (; i <= j; ++i) {
        a.push(String.fromCharCode(i));
    }
    return {letters: a, index: 0};
  } 
}