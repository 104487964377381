import Item from './Item';
import SETTINGS from '../../Settings';


// TODO CHANGE SIZE BY TRACKS LENGTH


const POSITION = {
  x: 0,
  y: 0.22,
  z: 10
};

class Filet extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "obstacle";

    this.state = {
      low: true,
      rows: 3,
      distanceDelay: 0
    };

  }
  setup () {
    this.scale = 0.4;
    this.create("filet_001");

    this.scene.add(this.sprite);

    this.sprite.position.set(POSITION.x, POSITION.y, SETTINGS.Z_TRACK_END);

    this.polishTexture();
  }
  testHit (player) {
    if ( this.testX(player.x) && this.testZ(player.z) && !player.state.jumping) {
      player.hit(this);
    }
  }
  testX () {
    //TODO CHECK FOR MORE THAN 3 WAYS
    return true;
  }
  reset () {
    super.reset();
    
    this.x = 0;  
  }
}

export { Filet };
