import _ from 'lodash';
import * as THREE from 'three';

export default class AtlasTexture {
  constructor(json, texture) {
    this._textures = {};
    texture.needsUpdate = true;
    let image = texture.image;

    json = JSON.parse(json);

    let frames = json.frames;
    _.keys(frames).forEach(function (key, i) {
      let frame = frames[key];
      let t = texture.clone();
      let data = frame.frame;
      t.repeat.set(data.w / image.width, data.h / image.height);
      t.offset.x = ((data.x) / image.width);
      t.offset.y = 1 - (data.h / image.height) - (data.y / image.height);
      t.needsUpdate = true;

      this._textures[frame.filename.replace('.png', '').toLowerCase()] = t;
    }, this);

  }
  get(id) {
    return this._textures[id];
  }
}