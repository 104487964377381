import STATE from '../State';
import MESSAGES from '../data/messages';
import { AST_False } from 'terser';

const CHALLENGER_MESSAGES = {
  challenge: "Try to defeat %%challenger-name%%!",
  win: "Congrats! You defeated %%challenger-name%%!"
};

export default class Interface {
  constructor (game) {
    this.game = game;

    this.element = document.getElementById('interface');

    this.scoreTag = this.element.querySelector("#score");
    
    this.itemsTag = this.element.querySelector("#items");

    this.timerTag = this.element.querySelector("#timer");

    this.speedTag = this.element.querySelector("#speed");

    this.bonusTag = this.element.querySelector("#bonus");

    document.addEventListener("keydown", (e) => this.onKeyDown(e));
  }

  onKeyDown ( event ) {
    if ( event.keyCode === 80 && this.game.state.PLAYING) { // P
      // this.pauseResume();
    }
  }
  pauseResume () {
    if ( this.game.state.RUNNING ) {

      this.game.pause();

    } else {

      this.game.resume();

    }
  }
  
  update () {
    this.scoreTag.innerText = this.game.state.PARTY.SCORE;

    this.bonusTag.innerText = this.game.state.PARTY.BONUS;

    this.timerTag.innerText = this.game.state.TIMER.text;

    if (this.challengerBarTag) {
      this.updateChallengerBar();
    }
    // if(this.game.itemGenerator){
    //   this.itemsTag.innerText = this.game.itemGenerator.items.length;
    // }

    // this.speedTag.innerText = Math.round(this.game.state.SPEED / this.game.state.ORIGINAL_SPEED * 100) / 100;
  }

  gameover () {
    let message = MESSAGES.SCORE.getMessage(this.game.state.PARTY.SCORE);

    document.getElementById("final-score").innerText = this.game.state.PARTY.SCORE;
    document.getElementById("time-score").innerText = this.game.state.TIMER.text;

    document.getElementById("end-message-text").innerText = message;
    STATE.score = this.game.state.PARTY.SCORE;
    STATE.time = this.game.state.TIMER.time.getTime();
  }

  reset () {
    this.update();
    this.initChallenge();
  }

  //CHALLENGE
  initChallenge () {
    this.challengeWon = false;

    if (this.game.app.challenge.active) {
      this.setupChallenger(this.game.app.challenge.challenger);
    } else {
      this.challengerBarTag = false;
    }
  }
  setupChallenger (challenger) {
    this.challenger = challenger;
    
    this.element.querySelector('.hud__challenger').classList.add('is-active');

    this.element.querySelector('#hud-challenger-name').innerText = challenger.name;

    this.element.querySelector('#hud-challenger-score').innerText = challenger.score;

    this.challengerBarTag = this.element.querySelector('.hud__challenger__bar');

    this.challengerMessageTag = this.element.querySelector('.hud-challenger-message');

    this.setupChallengerMessage('challenge');

  }
  updateChallengerBar () {

    if (this.challengeWon) return false;

    let progress = (this.game.state.PARTY.SCORE / this.challenger.score);
    
    progress = Math.min(1, progress);

    this.challengerBarTag.style.width = Math.round(progress * 100) + '%';   
    
    if (progress === 1) {
      this.setupChallengerMessage('win');  
      this.challengeWon = true;    
    }
  }
  setupChallengerMessage (message) {
    message = CHALLENGER_MESSAGES[message];

    message = message.replace('%%challenger-name%%', this.challenger.name);

    this.challengerMessageTag.querySelector('.message').innerText = message;

    this.challengerMessageTag.classList.add('is-active');

    setTimeout(()=>{
      this.challengerMessageTag.classList.remove('is-active');
    }, 5000);
  }
}