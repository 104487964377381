import * as THREE from 'three';
import SETTINGS from '../../Settings';

export default class People {
  constructor (game, settings = {}) {
    this.game = game;
    this.scene = game.scene;

    this.settings = settings;
    
    this.textures = [];

    this.group = new THREE.Group();

    this.scene.add(this.group);

    this.DEFAULTS = {
      x: 3, 
      y: 1
    };

    this.sprites = [];

    this.addTexture();

    for (let i = 0; i < 3; i++) {
      this.addRow(i);
    }

    this.group.rotation.y = this.settings.reversed ? -0.02 : 0.02;
    this.group.position.x = this.settings.reversed ? -0.1 : 0.1;
    this.group.position.z = -2;
    this.group.position.y = -0.1;
  }
  addTexture () {
    for (let i =1; i < 4; i++) {
      this.getTexture(i);
    }

  }
  getTexture (index) {
    // const maxAnisotropy = this.game.renderer.capabilities.getMaxAnisotropy();

    const sprite = this.game.TEXTURES['people' + index].clone();
    
    sprite.wrapS = THREE.RepeatWrapping; 
    // sprite.wrapT = THREE.RepeatWrapping;
    sprite.repeat.set(12,1);
    // sprite.anisotropy = maxAnisotropy;
    // sprite.magFilter = THREE.NearestFilter;
    sprite.needsUpdate = true;

    this.sprites.push(sprite);
  }
  addRow (index) {
    const sprite = this.sprites[index].clone();
    sprite.needsUpdate = true;

    this.textures.push(sprite);

    const geometry = new THREE.PlaneBufferGeometry( 24.5, 0.5, 1 );
    const material = new THREE.MeshBasicMaterial({map: sprite, transparent: true, depthWrite: true, depthTest: true });

    const mesh = new THREE.Mesh( geometry.clone(), material );   

    this.placeRow(mesh, index);

    this.group.add(mesh);
  }
  placeRow (row, index) {

    if (this.settings.reversed) {
      row.rotation.y = Math.PI / 2;
    } else {
      row.material.map.repeat.x = -12;
      row.rotation.y = -Math.PI / 2;
    }

    row.material.map.offset.x = 0.7 * index;

    let x = 2.6 - index * 0.2;
    
    if (this.settings.reversed) {
      x = -x;
    }

    row.position.set(
      x, 
      0.9 - index * 0.1,
      0.6 + index * 0.3
    );

  }
  update () {
    this.textures.forEach((texture) => {
      texture.offset.x += this.game.state.GAME_SPEED/3;
    });
  }
}