export default {
  SCORE: {
    messages : [
      {
        score: 0,
        text: "Not bad! Avantage!"
      },
      {
        score: 1000,
        text: "Bad Call!"
      },
      {
        score: 3000,
        text: "Serve it, Smash it, Win it, Love it."
      },
      {
        score: 9000,
        text: "Champions train; Losers complain."
      },
      {
        score: 15000,
        text: "Hustle, hit and never quit."
      },
      {
        score: 30000,
        text: "Game, Set, Match!"
      },
      {
        score: 50000,
        text: "You Got Served!"
      }
    ],
    getMessage: function (score) {

      let mess = null;

      this.messages.forEach((message) => {
        if (score >= message.score) {
          mess = message.text;
        }
      });

      return mess;
    } 
  }
};