import * as THREE from 'three';
import {TweenLite, Power2, Power1} from "gsap/TweenLite";
import {Back, Circ} from "gsap/TweenMax";
import Touche from "touchejs";
import SETTINGS from "../Settings";
import STATE from "../State";
import AnimatedSprite from './sprite/AnimatedSprite';
import FXSprite from './sprite/FXSprite';

const KEYBOARD_CODE = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  space: 32,
  shift: 16
};

const POSITION = {
  x: 0,
  y: 0.5,
  z: 2.1
};

const ORIGINAL_CAMERA_POSITION = {
  y: SETTINGS.LARGE_FOV ? 1 : 1.2
};

const ORIGINAL_ACTION_TIME = 0.35;
const ORIGINAL_ACTION_MOVE_TIME = 0.3;

const xGap = SETTINGS.TRACK_WIDTH;

export default class Player {
  constructor (game) {
    
    this.state = {
      action_time: ORIGINAL_ACTION_TIME,
      move_time: ORIGINAL_ACTION_MOVE_TIME,
      acting: false,
      jumping: false,
      crouching: false,
      dead: false,
      timeouts: {
        jumpStart: null,
        jumpEnd: null
      }
    };

    this.game = game;
    this.scene = game.scene;
    
    this.maxGap = xGap * ((SETTINGS.TRACKS-1)/2);

    document.addEventListener("keydown", (e) => this.onKeyDown(e));

    this._flipX = false;
    this._x = 0;

    if ( !SETTINGS.ORBIT ) {
      this.handleTouch();
    }

    this.create();

    this.addFx();

    this.testGamepad();
  }
  create () {
    if (this.sprite) {
      this.sprite.destroy();  
    }
    
    let json = this.game.FILES[STATE.character.json];
    
    let texture = this.game.TEXTURES[STATE.character.name + 'Sprite'];

    POSITION.y = STATE.character.type === "male" ? 0.57 : 0.5;

    this.sprite = new AnimatedSprite({
      json,
      texture,
      scene: this.scene,
      type: STATE.character.type,
      prefix: STATE.character.prefix,
      animations : {
        run: {
          slug: "run",
          delay: 4,
          loop: true,
          default: true
        },
        dash: {
          slug: "dash",
          delays: SETTINGS.DASH_SEQ ? SETTINGS.DASH_SEQ.split(',') : STATE.character.dash_times
        },
        jump: {
          slug: "jump",
          delays: SETTINGS.JUMP_SEQ ? SETTINGS.JUMP_SEQ.split(',') :  STATE.character.jump_times
        }
      }
    });
 
  }

  handleTouch () {

    Touche(this.game.renderer.domElement)
      .swipe({
        end: (e, data) => {
          if (!this.game.state.RUNNING || !this.game.state.PLAYING || this.game.cameraStory.blocked || this.state.dead) return null;

          if (data.swipe.direction == "left") {
            this.move(1);
          } else if (data.swipe.direction == "right") {
            this.move(-1);
          } else if (data.swipe.direction == "down") {
            this.jump();
          } else if (data.swipe.direction == "up") {
            this.crouch();
          }

        }
      });

  }
  onKeyDown (event) {
    if (!this.game.state.RUNNING || !this.game.state.PLAYING || this.game.cameraStory.blocked || this.state.dead) return null;
    
    let keyUsed = false;

    if(event.keyCode == KEYBOARD_CODE.left) {
      this.move(-1);
      keyUsed = true;
    } else if (event.keyCode == KEYBOARD_CODE.right) {
      this.move(1);
      keyUsed = true;
    } else if (event.keyCode == KEYBOARD_CODE.up || event.keyCode == KEYBOARD_CODE.space) {
      this.jump();
      keyUsed = true;
    } else if (event.keyCode == KEYBOARD_CODE.down || event.keyCode == KEYBOARD_CODE.shift) {
      this.crouch();
      keyUsed = true;
    }

    if ( SETTINGS.ORBIT ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }

    if (keyUsed) {
      event.preventDefault();
      return false;
    }
  }
  move (x = 0) {
    this._x = Math.max(Math.min(this.maxGap, this._x + xGap * x), -this.maxGap);

    TweenLite.to(this, this.state.move_time,{x:this._x, ease: Power2.easeOut});

    TweenLite.to(this.game.camera.position, this.state.move_time * 1.25,{x:this._x, ease: Power1.easeOut});
  }
  jump () {
    if (this.state.acting) {
      return false;
    }

    this.game.app.audio.playVoice('jumpStart');

    this.state.acting = true;

    this.state.jumping = true;
    
    this.sprite.play('jump');

    // clearTimeout(this.state.timeouts.jumpStart);

    clearTimeout(this.state.timeouts.jumpEnd);

    // this.state.timeouts.jumpStart = setTimeout(()=>{
      this.jumpMove();
    // }, 100);
  }
  jumpMove () {
    TweenLite.to(this.game.camera.position, this.state.action_time, {y: ORIGINAL_CAMERA_POSITION.y + 0.2});

    const z = SETTINGS.LARGE_FOV ? POSITION.z - 0.2 : POSITION.z ;
    
    TweenLite.to(this, this.state.action_time, {y:POSITION.y + 0.4, z: z, ease: Power1.easeOut, onComplete: ()=>{

      this.state.acting = false;

      this.game.app.audio.playVoice('jumpEnd');

      clearTimeout(this.state.timeouts.jumpEnd);

      this.state.timeouts.jumpEnd = setTimeout(()=>{
        this.state.jumping = false;
      }, this.state.action_time);

      TweenLite.to(this.game.camera.position, this.state.action_time, {y: ORIGINAL_CAMERA_POSITION.y });

      TweenLite.to(this, this.state.action_time ,{y:POSITION.y, z: POSITION.z});
    
      this.jumpFx.play();
    
    }});
  }
  crouch () {
    if (this.state.acting) {
      return false;
    }

    this.state.acting = true;
    this.state.crouching = true;
    this.game.app.audio.playVoice('dash');

    setTimeout(()=>{
      this.state.acting = false;
      setTimeout(()=>{
        this.state.crouching = false;
      }, 300);
    }, 300);

    this.dustFx.play();

    this.sprite.play('dash');
  }
  reset () {
    this._x = 0;
    this.x = 0;  
    this.state.dead = false;
  }
  hit (item) {    
    if ( !SETTINGS.GOD_MODE ) {

      this.state.dead = true;
      this.sprite.play('dash');
      this.game.dead();
    }
  }
  grab (score, type = "ball") {

    this.game.state.PARTY.addPoint(score);    
    
    if (type === "trophy") {
      this.game.app.audio.play('pickupTrophy');
    } else if (type === "gold") {
      this.game.app.audio.play('pickupGold');
    } else {
      this.game.app.audio.play('pickup');
    } 
  }
  addFx () {
    this.dustFx = new FXSprite({
      scene: this.scene,
      game: this.game,
      slug: "dash_dust",
      delay: 2,
      y:0.28,
      scale: 0.3
    });
    this.jumpFx = new FXSprite({
      scene: this.scene,
      game: this.game,
      slug: "jump_dust",
      delay: 8,
      y:0.34,
      scale: 0.3
    });

  }
  get x () {
    return this.sprite.x;
  }
  set x (_x) {
    this.sprite.x = _x;
  }
  get y () {
    return this.sprite.y;
  }
  set y (_y) {
    this.sprite.y = _y;
  }
  set z (_z) {
    this.sprite.z = _z;
  }
  get z () {
    return this.sprite.z;
  }
  update () {
    this.sprite.update(this.game.delta);

    if (this.dustFx.needsUpdate) {
      this.dustFx.update(this.x);
    }

    if (this.jumpFx.needsUpdate) {
      this.jumpFx.update(this.x);
    }
  }

  testGamepad () { 
    

    window.addEventListener('gc.analog.start', (event) => {
      let stick = event.detail;
      console.log(stick);
      this.controllerStickMove(event.detail.position);
    }, false);
    
    window.addEventListener('gc.button.press', (event) => {
      let button = event.detail;
      console.log(button);
      this.controllerButtonPressed(event.detail.name);
    }, false);
    
  }
  controllerButtonPressed (name) {
    if (!this.game.state.RUNNING || !this.game.state.PLAYING || this.game.cameraStory.blocked || this.state.dead) return null;

    if(name === SETTINGS.ARCADE_BUTTON.up) {
      this.jump();
    } else if (name === SETTINGS.ARCADE_BUTTON.down) {
      this.crouch();
    } else if (name === SETTINGS.ARCADE_BUTTON.joystick.right) {
      this.move(1);
    } else if (name === SETTINGS.ARCADE_BUTTON.joystick.left) {
      this.move(-1);      
    }
  }
  controllerStickMove (position) {
    if (!this.game.state.RUNNING || !this.game.state.PLAYING || this.game.cameraStory.blocked || this.state.dead) return null;

    if(Math.abs(position.x) < SETTINGS.ARCADE_BUTTON.threshold) {
      return;
    }

    if(position.x > 0) {
      this.move(1);
    } else if (position.x < 0) {
      this.move(-1);
    }

    // if(position.y < 0) {
    //   this.jump();
    // } else if (position.y > 0) {
    //   this.crouch();
    // }
  }
  
}