import SETTINGS from './Settings';

export default class GameAnalytics {
  constructor (app) {
    this.app = app;
  }
  load () {
    this.setupGA();
    this.bindEvents();
  }
  setupGA () {
    if(typeof window.gtag == "undefined") return false;

    gtag('config', SETTINGS.ANALYTICS.UA_ID);
    gtag('event', event, { 'send_to': SETTINGS.ANALYTICS.UA_ID });

  }
  sendGA (event) {
    if(typeof window.gtag == "undefined") return false;

    if (typeof event === "string") {
      gtag('event', event, { send_to: SETTINGS.ANALYTICS.UA_ID });
    } else {
      gtag('event', event.name, { event_category: event.category, event_label: event.label, send_to: SETTINGS.ANALYTICS.UA_ID });
    }

  }
  sendDC (sendTo) {
    if(typeof window.gtag == "undefined") return false;

    gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'send_to': sendTo
    });
  }
  sendFB (event) {
    if(typeof window.fbq == "undefined") return false;

    if (event === "ViewContentGaming2" || event === "GameOverGaming" || event === "PageRankingGaming") {
      fbq('track', 'PageView');
    }

    fbq('trackCustom', event);
  }
  sendSnap (event) {
    if(typeof window.snaptr == "undefined") {
      return false;
    }
    snaptr('track', event);
  }
  bindEvents () {
    const self = this;

    [].forEach.call(document.querySelectorAll('a[data-track-ga], .btn[data-track-ga]'), function(el) {
      el.addEventListener('click', () => {
        self.onClick(el);
      });
    });
  }
  onClick (element) {
    const event = {
      ga: element.getAttribute('data-track-ga'),
      dc: element.getAttribute('data-track-dc'),
      fb: element.getAttribute('data-track-fb'),
      snap: element.getAttribute('data-track-snap'),
    };
    
    this.sendEvent(event);
  }
  sendEvent (event) {
    if (SETTINGS.FB_ACTIVE || SETTINGS.ARCADE_MODE) {
      return false;
    }

    if (event.dc) {
      this.sendDC(event.dc);
    }
    
    if (event.fb) {
      this.sendFB(event.fb);
    }

    if (event.snap) {
      this.sendSnap(event.snap);
    }

    if (event.ga) {
      this.sendGA(event.ga);
    }

    if (event.dc || event.fb || event.snap || event.ga) {
      // console.log(event);
    }
  }
  onOpenScreen (screen) {

    if(!screen) return false;

    if(!screen.element) return false;

    const event = {
      ga: screen.element.getAttribute('data-track-ga'),
      dc: screen.element.getAttribute('data-track-dc'),
      fb: screen.element.getAttribute('data-track-fb'),
      snap: screen.element.getAttribute('data-track-snap'),
      from: 'open screen'
    };
    
    this.sendEvent(event);
  }
}