import Item from './Item';
import SETTINGS from '../../Settings';
import _ from 'lodash';
import * as THREE from 'three';

const POSITION = {
  x: 0,
  y: 0.2,
  z: 0
};

const OPTIONS = {
  scale: 0.4,
  goldScale: 0.5,
  y: 0.2,
  goldY: 0.26,
  slug: "ball",
  slugGold: "ball_gold"
};

class BallGold extends Item {
  constructor (itemGenerator) {
    super (itemGenerator);

    this.type = "ball";

    this.isGold = false;
    
    this.sprite.visible = true;

    this.x = Math.round(_.random(-SETTINGS.TRACKS/2, SETTINGS.TRACKS/2)) * SETTINGS.TRACK_WIDTH;

    // this.z = SETTINGS.Z_TRACK_END;

    this.needsUpdate = false;

    this.frameDelay = 10;

    this.tilt = 0;
  }
  setup () {
    this.json = JSON.parse(this.game.FILES.elementsJson);

    this.goldFrame = 1;

    this.normalFrame = 0;

    this.scale = OPTIONS.scale;

    this.frames = [];

    this._index = 1;

    this.create();

    this.sprite.position.set(POSITION.x, POSITION.y, SETTINGS.Z_TRACK_END);

    this.material.opacity = 1;

    this.ready = true;

    this.switchGold(false);
  }
  create () {
    this.texture = this.game.TEXTURES.elementsSpritesheet.clone();

    this.texture.needsUpdate = true;
    this.material = new THREE.SpriteMaterial( { map: this.texture, color: 0xffffff, depthWrite: true } );
    this.sprite = new THREE.Sprite( this.material );

    _.keys(this.json.frames).forEach( (key, i) => {
      if (this.json.frames[key].filename === OPTIONS.slug || this.json.frames[key].filename === OPTIONS.slugGold) {
        this.frames.push(this.json.frames[key].frame);
        this.sourceSize = this.json.frames[key].sourceSize;
      }
    }, this);
  }
  switchGold (goldMode = true) {
    // this.needsUpdate = goldMode;
    this.isGold = goldMode;

    if (!goldMode) {
      this.scale = OPTIONS.scale;
      this.y = OPTIONS.y;
      this.setFrame(this.frames[this.normalFrame]);
    } else {
      this.scale = OPTIONS.goldScale;
      this.y = OPTIONS.goldY;
      this.setFrame(this.frames[this.goldFrame]);
    } 
  }
  animate () {
    if (this._index < this.frames.length - 1) {
      this._index += 1;
    } else {
      this._index = 1;
    }
    
    this.setFrame(this.frames[this._index]);
  }
  update () {    
    this.tilt += 1;

    if (this.tilt >= this.frameDelay) {
      this.tilt = 0;
      // this.animate();
    }
  }
  testHit (player) {
    if ( this.testX(player.x) && this.testZ(player.z) && this.state.active) {
      player.grab(SETTINGS.BALL_SCORE, this.isGold ? "gold" : "ball");
      this.hide();
    }
  }
  testX (xPosition) {
    return Math.abs(this.x - xPosition) < 0.2;
  }
  testZ (zPosition) {
    return Math.abs(this.z - zPosition) < 0.3;
  }
  reset () {
    this.show();

    const minRow = Math.max(0, this.itemGenerator.state.lastBallRow - 1);
    
    const maxRow = Math.min(SETTINGS.TRACKS - 1, this.itemGenerator.state.lastBallRow + 1);

    this.row = _.random(minRow, maxRow);

    this.x = (this.row - (SETTINGS.TRACKS-1)/2) * SETTINGS.TRACK_WIDTH;

    this.itemGenerator.state.lastBallRow = this.row;

    this.z = SETTINGS.Z_TRACK_END;
  }
};

export { BallGold };