import Screen from "./Screen";
import STATE from "../State";
import Slider from "./components/Slider";

const characters = [
  {
    id:0,
    name: 'female0',
    json: 'female0Json',
    type: 'female',
    prefix: "01/",
    dash_times: [4,4,4,12,5,5], // 34
    jump_times: [6,3,3,3,5,4,7,3], //34
    label: "female-caucasian"
  },
  {
    id:1,
    name: 'male0',
    json: 'male0Json',
    type: 'male',
    prefix: "01/",
    dash_times: [4,4,4,12,5,5],
    jump_times: [6,4,5,5,5,6,6,5], //38,
    label: "male-afro"

  },
  {
    id:5,
    name: 'female2',
    type: 'female',
    json: 'female2Json',
    prefix: "03/",
    dash_times: [4,4,4,12,5,5],
    jump_times: [6,3,3,3,5,4,7,3],
    label: "female-asian"
  },
  {
    id:4,
    name: 'male1',
    json: 'male1Json',
    type: 'male',
    prefix: "02/",
    dash_times: [4,4,4,12,5,5],
    jump_times: [6,4,5,5,5,6,6,5], //38
    label: "male-asian"
  },
  {
    id:3,
    name: 'female1',
    type: 'female',
    json: 'female1Json',
    prefix: "02/",
    dash_times: [4,4,4,12,5,5],
    jump_times: [6,3,3,3,5,4,7,3],
    label: "female-afro"
  },
  {
    id:6,
    name: 'male2',
    json: 'male2Json',
    type: 'male',
    prefix: "03/",
    dash_times: [4,4,4,12,5,5],
    jump_times: [6,4,5,5,5,6,6,5], //38
    label: "male-caucasian"
  },
];

export default class SelectionScreen extends Screen {
  init () {
    super.init();
    
    this.slider = new Slider({
      element: this.element.querySelector('.selector'),
      onSlide: this.onSlide.bind(this)
    });

    STATE.character = characters[0];
  }
  onSlide (index) {
    
    STATE.character = characters[index];
    
    this.screensManager.app.audio.play('select');
  }
  close (callback) {
    if (!super.close(callback)) {
      return false;
    }

    this.screensManager.app.runner.player.create();

    this.screensManager.app.analytics.sendEvent({
      ga: {
        name: "select",
        category: 'Characters',
        label: STATE.character.label
      }
    });

  }
  onDirectionnal (direction, event) {
    if (direction === 'left') {
      this.slider.slide(-1);
    } else if (direction === 'right') {
      this.slider.slide(1);
    }
  }
  
};