export default {
  list: [
    "chair",  
    "chair",  
    "chair",  
    "chair",  
    "chair",  
    "filet",
    "filet",
    "filet",
    "bench",
    "bench",
    "bench",  
    "digitsSmall",
    "digitsSmall",
    "digitsSmall",
    "digits",
    "digits",
  ],
  intro: [
    "chair",
    "bench",
    "filet"
  ]

};