import * as THREE from 'three';
import SETTINGS from '../../Settings';

const POSITION = {
  x: 0,
  y: 0.4,
  z: 0
};

export default class Item {
  constructor (itemGenerator) {

    this.type = "item";

    this.game = itemGenerator.game;

    this.itemGenerator = itemGenerator;
    
    this.scene = this.game.scene;

    this.scale = 1;

    this.needsUpdate = false;
 
    this.state = {
      active: false,
      low: false,
      up: false,
      rows: 1,
      distanceDelay: 0
    };

    this.setup();

    this.z = -8 + this.itemGenerator.group.children.length * 0.3;

  }
  setup () {
    this.create("ball");
  }
  create (name) {
    this.texture = this.game.TEXTURES.elementsAtlas.get(name);
  
    this.material = new THREE.SpriteMaterial( { map: this.texture, color: 0xffffff, depthWrite: true } );
    this.sprite = new THREE.Sprite( this.material );

    this.sprite.scale.set((this.texture.repeat.width * this.texture.image.width) / (this.texture.repeat.height* this.texture.image.height) * this.scale, this.scale, 1);
  }
  setFrame (frame) {
    const image = this.texture.image;
    this.texture.repeat.set(frame.w / image.width, frame.h / image.height);
    this.texture.offset.x = ((frame.x) / image.width);
    this.texture.offset.y = 1 - (frame.h / image.height) - (frame.y / image.height);

    this.sprite.scale.set(frame.w / frame.h * this.scale, this.scale, 1);
  }
  polishTexture () {
    // const maxAnisotropy = this.game.renderer.capabilities.getMaxAnisotropy();

    // this.spriteMap.anisotropy = maxAnisotropy;

    this.texture.minFilter = THREE.LinearFilter;
    // this.texture.magFilter = THREE.NearestFilter;
    this.texture.needsUpdate = true;
  }
  show () {
    this.material.opacity = 1;
    this.state.active = true;
    // this.material.visible = true;
  }
  hide () {
    this.material.opacity = 0.000001;
    this.state.active = false;
    // this.material.visible = false;
  }
  get x () {
    return this.sprite.position.x;
  }
  set x (_x) {
    this.sprite.position.x = _x;
  }
  get y () {
    return this.sprite.position.y;
  }
  set y (_y) {
    this.sprite.position.y = _y;
  }
  get z () {
    return this.sprite.position.z;
  }
  set z (_z) {
    this.sprite.position.z = _z;
  }
  destroy () {
    this.material.dispose();
    this.texture.dispose();
  }
  testHit (player) {
  
    if ( this.testX(player.x) && this.testZ(player.z) && this.state.active) {
      
      // if ( !this.state.low && !this.state.up ) {
      //   player.hit();
      // } else if ( this.state.low && !player.state.jumping ) {
      //   player.hit();
      // }  else if ( this.state.up && !player.state.crouching ) {
      //   player.hit();
      // }

      player.hit(this);

      // return true;
    }
  }
  testX (xPosition) {
    return Math.abs(this.x - xPosition) < 0.1;
  }
  testZ (zPosition) {
    return zPosition >= this.z && Math.abs(this.z - zPosition) < 0.2;
  }
  reset () {
    this.show();

    this.x = Math.round(_.random(-SETTINGS.TRACKS/2, SETTINGS.TRACKS/2)) * SETTINGS.TRACK_WIDTH;
    this.z = SETTINGS.Z_TRACK_END;
  }
}