import {Howl, Howler} from 'howler';
import STATE from '../State';
import SETTINGS from '../Settings';
import _ from 'lodash';
import audiosprite from './audiosprite';

export default class AudioGame{
  constructor (app) {
    this.app = app;


    this.themes = {
      intro: {
        slug: "theme_1",
        volume: 0.5
      },
      ingame: {
        slug: "theme_2",
        volume: 0.5,
      },
      end: {
        slug: "theme_3",
        volume: 0.2,
      }
    };

    this.ambiances = {
      crowd: {
        slug: "crowd_constant",
        volume: 0.0001,
      },
      crowdCheers: {
        slug: "crowd_cheers",
        volume: 0.2,
      },
      crowdOh: {
        slug: "crowd_oh",
        volume: 0.3,
      }
    };

    this.UI = {
      top: {
        slug: "321",
        volume: 1,
      },
      go: {
        slug: "go",
        volume: 1,
      },
      letsgo: {
        slug: "lets_go",
        volume: 1,
      },
      select: {
        slug: "select_player",
        volume: 0.4,
      },
      start: {
        slug: "start_button",
        volume: 1,
      },
    };

    this.SFX = {
      pickup: {
        slug: "pickup",
        volume: 1,
      },

      pickupGold: {
        slug: "pickup_gold",
        volume: 1,
      },

      pickupTrophy: {
        slug: "pickup_trophy",
        volume: 0.3,
      },
      
      femalejumpStart: {
        slug: "jump_start_female",
        volume: 0.8,
      },
      femalejumpEnd: {
        slug: "jump_end_female",
        volume: 0.8,
      },
      femaledash: {
        slug: "dash_female",
        volume: 0.6
      },
      femalechoc: {
        slug: "collision_female",
        volume: 1,
      },

      malejumpStart: {
        slug: "jump_start_male",
        volume: 0.8,
      },
      malejumpEnd: {
        slug: "jump_end_male",
        volume: 0.8,

      },
      maledash: {
        slug: "dash_male",
        volume: 0.6
      },
      malechoc: {
        slug: "collision_male",
        volume: 1,
      },
    };

    this.playlist = _.assign({}, this.ambiances, this.SFX, this.UI,  this.themes);

    this.currents = {
      theme: null,
      ambiance: null
    };

    this.handleSound();

    this.handleClickSound();

    this.ready = false;

    this.sounds = new Howl(audiosprite);

    this.sounds.once('load', ()=>{
      this.ready = true;  
    });
    
  }
  startIntro () {
    if (SETTINGS.SKIP_INTRO) {
      this.play('ingame', {loop: true, main: 'theme'});
    } else {
      this.play('intro', {loop: true, main: 'theme'});
    }
  }
  // play (id, params = {loop: false, main: false}) {
  //   const sound = this.playlist[id];
    
  //   if (!sound) {
  //     return console.log('no sound : ' + id);
  //   }

  //   if (!sound.howl) {
  //     sound.howl = new Howl({
  //       src: "src/assets/audio/" + sound.file + ".mp3",
  //       loop: params.loop,
  //       volume : sound.volume ? sound.volume : 1.0
  //     });
  //   }
    
  //   if (params.main) {
  //     if (this.currents[params.main]) {
  //       this.currents[params.main].howl.stop();
  //     }
  //     this.currents[params.main] = sound;
    
  //     if (params.main === 'theme') {
  //       sound.howl.fade(0.0, sound.volume, 1000);
  //     }
  //   }
    
  //   sound.howl.play();
  // }
  play (slug, params = {loop: false, main: false}) {
    let sound = this.playlist[slug];
    
    if (!sound) {
      return console.log('no sound : ' + sound);
    }

    if (sound.id) {
      sound.id = this.sounds.stop(sound.id);
    }

    sound.id = this.sounds.play(sound.slug);

    if (sound.volume) {
      this.sounds.volume(sound.volume, sound.id);
    }

    if (params.loop) {
      this.sounds.loop(true, sound.id);
    }

    if (params.main) {  
      if (params.main === 'theme') {
        this.fadeOutCurrentTheme();

        this.currents[params.main] = sound.id;
        
        this.sounds.fade(0.0, sound.volume, 500, sound.id);

      } else if (this.currents[params.main]) {

        this.sounds.stop(this.currents[params.main]);
        
        this.currents[params.main] = sound.id;
      }
    }

  }
  playVoice (id) {
    this.play(STATE.character.type + id);
  }
  playCrowd () {
    this.play('crowd', {loop: true});
    // this.play('crowdCheers');
  }
  upCrowd () {
    this.fadeCrowd(true);
  }
  downCrowd () {
    this.fadeCrowd(false);
  }
  stopCrowd () {
    this.fadeCrowd(false, true);
  }
  fadeCrowd (up = true, toPause = false) {
    let sound = this.playlist.crowd;

    // if (!sound.id) {
    //   sound.id = this.sounds.play(sound.slug);
    // }
    
    const fade = up ? 0.1 : -0.1;
    
    const volume = toPause ? 0 : Math.min(1, this.sounds.volume(sound.id) + fade);
     
    this.sounds.fade(this.sounds.volume(sound.id), volume, 500, sound.id);
    
    if (this.playlist.crowdCheers.id) {
      this.sounds.volume(volume, this.playlist.crowdCheers.id);
    }

    if (toPause) {
      setTimeout(()=>{
        this.sounds.stop(sound.id);
        this.sounds.stop(this.playlist.crowdCheers.id);
      }, 500);
    }

  }
  fadeOutCurrentTheme () {
    if (this.currents.theme) {
      // this.sounds.stop(this.currents.theme);
      let id = this.currents.theme;

      this.sounds.fade(this.sounds.volume(this.currents.theme), 0.0, 500, this.currents.theme);
      
      setTimeout(()=>{
        this.sounds.stop(id);
      }, 500);

      this.currents.theme = false;
    }
  }
  mute () {
    Howler.volume(0);
    STATE.options.mute = true;
    this.soundButton.classList.add('muted');
  }
  unmute () {
    Howler.volume(1);
    STATE.options.mute = false;
    this.soundButton.classList.remove('muted');
  }
  pauseAll () {
    Howler.mute(true);
  }
  resumeAll () {
    Howler.mute(false);
  }
  handleSound () {
    this.soundButton = document.getElementById('sound-btn');
    
    this.soundButton.onclick = () => {
      if (STATE.options.mute) {
        this.unmute();
      } else {
        this.mute();
      }
    };
  }
  handleClickSound () {
    const self = this;

    [].forEach.call(document.querySelectorAll('[data-sound-onclick]'), function(el) {
      el.addEventListener('click', () => {
        self.play(el.getAttribute('data-sound-onclick'));
      });
    });

  }

}